define("map-ui/components/user/user-details", ["exports", "ember", "ember-can"], function (exports, _ember, _emberCan) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    flash: service(),
    ajax: service(),
    ability: _emberCan.computed.ability("user", "model"),
    changePassword: false,
    createPassword: false,

    showDelete: true,

    showRoles: true,
    showingClientSelector: computed.oneWay("ability.canEditClient"),

    medaCheckChecked: computed("model", function () {
      if (get(this, "model.roles")) {
        return get(this, "model.roles").includes("MedaCheck");
      }
      return false;
    }),

    careGiverChecked: computed("model", function () {
      if (get(this, "model.roles")) {
        return get(this, "model.roles").includes("Care Giver");
      }
      return false;
    }),

    adminChecked: computed("model", function () {
      if (get(this, "model.roles")) {
        return get(this, "model.roles").includes("Administrator");
      }
      return false;
    }),

    reporterChecked: computed("model", function () {
      if (get(this, "model.roles")) {
        return get(this, "model.roles").includes("Reporter");
      }
      return false;
    }),

    rolesArray: computed("medaCheckChecked", "careGiverChecked", "adminChecked", function () {
      var rolesArray = [];
      if (get(this, "medaCheckChecked")) {
        rolesArray.addObject("MedaCheck");
      }
      if (get(this, "careGiverChecked")) {
        rolesArray.addObject("Care Giver");
      }
      if (get(this, "adminChecked")) {
        rolesArray.addObject("Administrator");
      }
      if (get(this, "reporterChecked")) {
        rolesArray.addObject("Reporter");
      }
      return rolesArray;
    }),

    deleteField: "",

    canDeleteUser: computed("deleteField", function () {
      if (get(this, "model.username")) {
        return get(this, "deleteField").toUpperCase() === get(this, "model.username").toUpperCase();
      }
      return false;
    }),

    actions: {
      save: function save() {
        var _this = this;

        get(this, "model").validate().then(function () {
          get(_this, "model").set("roles", get(_this, "rolesArray"));
          get(_this, "model").save().then(function () {
            get(_this, "flash").success("User saved!");
          })["catch"](function () {
            get(_this, "flash").danger("Unable to save user.");
          });
        })["catch"](function () {
          set(_this, "errors", get(_this, "model.errors"));
        });
      },
      rollback: function rollback() {
        get(this, "model").rollbackAttributes();
        this.transitionToRoute("users");
      },
      "delete": function _delete() {
        var _this2 = this;

        get(this, "model").destroyRecord().then(function () {
          get(_this2, "flash").success("User deleted!");
          _this2.transitionToRoute("users");
        });
      }
    }
  });
});