define("map-ui/components/patient/alert/alert-schedule/schedule-table", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var service = _ember["default"].inject.service;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    classNames: ["alert-schedule-table", "flex"],

    store: service(),
    eventBus: service(),

    actions: {
      "new": function _new() {
        var alertSchedule = get(this, "store").createRecord("alert-schedule", {
          alert: get(this, "model")
        });
        this.get("eventBus").publish("alertScheduleRowOpened", alertSchedule);
      },

      save: function save() {
        this.sendAction("save");
      }
    }
  });
});