define("map-ui/services/session-user", ["exports", "ember"], function (exports, _ember) {
  var service = _ember["default"].inject.service;
  var RSVP = _ember["default"].RSVP;
  var Service = _ember["default"].Service;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Service.extend({
    session: service("session"),
    store: service(),

    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      if (get(this, "session.isAuthenticated")) {
        return this.get("store").findRecord("user", "me", { include: "client.graph-collections" }).then(function (user) {
          _this.set("user", user);
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});