define("map-ui/initializers/map-ember", ["exports", "ember", "liquid-fire/components/liquid-spacer"], function (exports, _ember, _liquidFireComponentsLiquidSpacer) {
  exports.initialize = initialize;
  var Route = _ember["default"].Route;
  var Component = _ember["default"].Component;
  var TextField = _ember["default"].TextField;
  var LinkComponent = _ember["default"].LinkComponent;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var testing = _ember["default"].testing;

  function initialize() {
    Route.reopen({
      scroll: true,
      layout: "default",
      isFullscreenActive: false,

      activate: function activate() {
        this._super.apply(this, arguments);

        var application = this.controllerFor("application");

        set(application, "isDropdownOpen", false);

        if (get(this, "scroll")) {
          window.scroll(0, 0);
        } else {
          set(this, "scroll", true);
        }
      },

      refresh: function refresh() {
        set(this, "scroll", false);
        this._super.apply(this, arguments);
      },

      setupController: function setupController() {
        this._super.apply(this, arguments);

        var application = this.controllerFor("application");
        var isFullscreenActive = get(this, "isFullscreenActive");

        application.set("layout", "layouts/" + (get(this, "layout") || "default"));
        application.set("isFullscreenActive", isFullscreenActive);
      },

      actions: {
        didTransition: function didTransition() {
          this._super.apply(this, arguments);

          if (window.Intercom) {
            window.Intercom("update");
          }
        }
      }
    });

    TextField.reopen({
      autocomplete: "off"
    });

    _liquidFireComponentsLiquidSpacer["default"].reopen({
      init: function init() {
        this._super.apply(this, arguments);
        if (testing) {
          set(this, "enabled", false);
        }
      }
    });

    Component.reopen({
      init: function init() {
        this._super.apply(this, arguments);
        if (get(this, "testId")) {
          this.attributeBindings = ["testId:test-id"];
        }
      }
    });

    LinkComponent.reopen({
      attributeBindings: ["data-tt"]
    });
  }

  exports["default"] = {
    name: "map-ember-init",
    initialize: initialize
  };
});