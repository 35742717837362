define("map-ui/components/statistics/types/line-statistic", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var service = _ember["default"].inject.service;
  exports["default"] = _ember["default"].Component.extend({
    moment: service(),

    chartOptions: {
      maintainAspectRatio: false,
      legend: {
        display: false
      }
    },

    xLabel: computed("data", function () {
      return get(this, "data.labels.x");
    }),

    yLabel: computed("data", function () {
      return get(this, "data.labels.y");
    }),

    momentFormat: "M/DD/YYYY h:mm a",

    lineData: computed("data.values", function () {
      var _this = this;

      return {
        labels: get(this, "data.values").map(function (value) {
          return moment(value.x).format(_this.get("momentFormat"));
        }),
        datasets: [{
          label: get(this, "yLabel"),
          data: get(this, "data.values").mapBy("y")
        }]
      };
    })
  });
});