define("map-ui/utils/cron-constructor", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var Object = _ember["default"].Object;
  exports["default"] = Object.extend({
    isDaily: computed.equal("typeOfScheduleField", "daily"),
    isWeekly: computed.equal("typeOfScheduleField", "weekly"),
    isMonthly: computed.equal("typeOfScheduleField", "monthly"),

    getMinuteValue: function getMinuteValue() {
      if (get(this, "minutes")) {
        return get(this, "minutes");
      }
      return "*";
    },

    getHourValue: function getHourValue() {
      if (get(this, "hour")) {
        if (get(this, "period") === "AM") {
          if (get(this, "hour") === "12") {
            return "0";
          } else {
            return get(this, "hour");
          }
        } else {
          if (get(this, "hour") === "12") {
            return "12";
          } else {
            return parseInt(get(this, "hour")) + 12;
          }
        }
      }
      return "*";
    },

    getDayValue: function getDayValue() {
      if (get(this, "isMonthly") && get(this, "dayOfMonth")) {
        return get(this, "dayOfMonth").replace(/\D+$/, "");
      }
      return "*";
    },

    getMonthValue: function getMonthValue() {
      return "*";
    },

    getDayOfWeekValue: function getDayOfWeekValue() {
      if (get(this, "isWeekly")) {
        var dayString = "";
        if (get(this, "mondayCheckbox")) {
          dayString += "1";
        }
        if (get(this, "tuesdayCheckbox")) {
          dayString += dayString.length > 0 ? ",2" : "2";
        }
        if (get(this, "wednesdayCheckbox")) {
          dayString += dayString.length > 0 ? ",3" : "3";
        }
        if (get(this, "thursdayCheckbox")) {
          dayString += dayString.length > 0 ? ",4" : "4";
        }
        if (get(this, "fridayCheckbox")) {
          dayString += dayString.length > 0 ? ",5" : "5";
        }
        if (get(this, "saturdayCheckbox")) {
          dayString += dayString.length > 0 ? ",6" : "6";
        }
        if (get(this, "sundayCheckbox")) {
          dayString += dayString.length > 0 ? ",7" : "7";
        }
        return dayString;
      }
      return "*";
    },

    toCronString: function toCronString() {
      return [this.getMinuteValue(), this.getHourValue(), this.getDayValue(), this.getMonthValue(), this.getDayOfWeekValue()].join(" ");
    }
  });
});