define("map-ui/models/device", ["exports", "ember-data"], function (exports, _emberData) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  exports["default"] = Model.extend({
    readableName: attr("string"),
    identifier: attr("string"),
    lastSeen: attr("date"),
    createdAt: attr("date"),

    patient: belongsTo("patient")
  });
});