define("map-ui/app", ["exports", "ember", "map-ui/resolver", "ember-load-initializers", "map-ui/config/environment"], function (exports, _ember, _mapUiResolver, _emberLoadInitializers, _mapUiConfigEnvironment) {
  var Application = _ember["default"].Application;

  var App = undefined;

  _ember["default"].MODEL_FACTORY_INJECTIONS = true;

  App = Application.extend({
    modulePrefix: _mapUiConfigEnvironment["default"].modulePrefix,
    podModulePrefix: _mapUiConfigEnvironment["default"].podModulePrefix,
    Resolver: _mapUiResolver["default"]
  });

  (0, _emberLoadInitializers["default"])(App, _mapUiConfigEnvironment["default"].modulePrefix);

  exports["default"] = App;
});