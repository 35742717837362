define("map-ui/models/client", ["exports", "ember-data", "map-ui/mixins/validators/client"], function (exports, _emberData, _mapUiMixinsValidatorsClient) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  var hasMany = _emberData["default"].hasMany;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsClient["default"], {
    name: attr("string"),
    code: attr("string"),

    graphCollections: hasMany("graph-collections", { async: true })
  });
});