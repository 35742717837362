define("map-ui/mixins/ability/has-role", ["exports", "ember"], function (exports, _ember) {
  var service = _ember["default"].inject.service;
  var Mixin = _ember["default"].Mixin;
  var get = _ember["default"].get;
  exports["default"] = Mixin.create({
    sessionUser: service(),

    hasRole: function hasRole(name) {
      if (get(this, "sessionUser.user.roles")) {
        if (get(this, "sessionUser.user.roles").indexOf("MedaCheck") > -1) {
          return true;
        }
        if (get(this, "sessionUser.user.roles").indexOf(name) > -1) {
          return true;
        }
      }
      return false;
    },

    hasAnyRole: function hasAnyRole(names) {
      for (var arg = 0; arg < arguments.length; ++arg) {
        var arr = arguments[arg];
        if (this.hasRole(arr)) {
          return true;
        }
      }
      return false;
    }
  });
});