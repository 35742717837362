define("map-ui/models/address", ["exports", "ember-data"], function (exports, _emberData) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  exports["default"] = Model.extend({
    name: attr("string"),
    line1: attr("string"),
    line2: attr("string"),
    city: attr("string"),
    county: attr("string"),
    state: attr("string"),
    zip: attr("string"),
    country: attr("string")
  });
});