define("map-ui/routes/users/create", ["exports", "ember", "map-ui/mixins/allowed-route-mixin", "map-ui/mixins/reset-model-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin, _mapUiMixinsResetModelRouteMixin) {
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], _mapUiMixinsResetModelRouteMixin["default"], {
    ability: "create user",

    model: function model() {
      return get(this, "store").createRecord("user", {
        person: get(this, "store").createRecord("person", {
          address: get(this, "store").createRecord("address")
        })
      });
    }
  });
});