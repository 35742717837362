define("map-ui/models/alert-job", ["exports", "ember-data"], function (exports, _emberData) {
  var Model = _emberData["default"].Model;
  var belongsTo = _emberData["default"].belongsTo;
  var attr = _emberData["default"].attr;
  exports["default"] = Model.extend({
    notificationDatetime: attr("date"),
    followUpDatetime: attr("date"),
    notifiedAt: attr("date"),
    followedUpAt: attr("date"),
    responseAt: attr("date"),
    createdAt: attr("date"),
    deletedAt: attr("date"),
    seenAt: attr("date"),

    alertSchedule: belongsTo("alertSchedule", { async: true })
  });
});