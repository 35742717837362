define("map-ui/abilities/drug", ["exports", "ember", "ember-can", "map-ui/mixins/ability/has-role"], function (exports, _ember, _emberCan, _mapUiMixinsAbilityHasRole) {
  var computed = _ember["default"].computed;
  exports["default"] = _emberCan.Ability.extend(_mapUiMixinsAbilityHasRole["default"], {
    canShow: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver", "Reporter");
    }),

    canShowAll: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver", "Reporter");
    }),

    canEdit: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver");
    }),

    canCreate: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver");
    })
  });
});