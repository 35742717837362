define("map-ui/components/plan/plan-selector", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["autofill-selector"],

    store: _ember["default"].inject.service(),
    flash: _ember["default"].inject.service(),

    searchQuery: _ember["default"].computed.oneWay("model.client.name"),

    actions: {
      carePlanNameChanged: function carePlanNameChanged() {
        var _this = this;

        this.get("store").query("care-plan", { query: this.get("searchQuery") }).then(function (response) {
          _this.set("carePlans", response);
        });
      },
      carePlanSelected: function carePlanSelected(carePlan) {
        var _this2 = this;

        this.set("searchQuery", null);
        this.set("carePlans", null);
        this.get("model.carePlans").pushObject(carePlan);
        this.get("model").save().then(function () {
          _this2.get("flash").success("Care plan added to patient!");
        })["catch"](function () {
          _this2.get("flash").error("Unable to add care plan to patient!");
        });
      }
    }
  });
});