define("map-ui/models/alert", ["exports", "ember-data", "ember", "map-ui/mixins/validators/alert", "map-ui/mixins/models/alert/alert-type", "map-ui/mixins/managed-relationships-mixin", "map-ui/config/environment"], function (exports, _emberData, _ember, _mapUiMixinsValidatorsAlert, _mapUiMixinsModelsAlertAlertType, _mapUiMixinsManagedRelationshipsMixin, _mapUiConfigEnvironment) {
  var Model = _emberData["default"].Model;
  var belongsTo = _emberData["default"].belongsTo;
  var attr = _emberData["default"].attr;
  var hasMany = _emberData["default"].hasMany;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsAlert["default"], _mapUiMixinsModelsAlertAlertType["default"], _mapUiMixinsManagedRelationshipsMixin["default"], {
    message: attr("string"),
    notes: attr("string"),
    followUpType: attr("string"),
    quantity: attr("string", { defaultValue: "1" }),
    category: attr("string"),

    patient: belongsTo("patient", { async: true }),
    drug: belongsTo("drug", { async: true, managed: true }),

    alertSchedules: hasMany("alert-schedule", { async: true }),

    imageUrl: _ember["default"].computed("category", function () {
      if (this.get("category") === "medication") {
        return this.get("drug.imageUrl");
      } else if (this.get("category") === "spirometry") {
        return _mapUiConfigEnvironment["default"].cdn + "/images/common/icons_inhaler_spirometer_1.png";
      }
      return _mapUiConfigEnvironment["default"].cdn + "/images/common/icons_alarm.png";
    }),

    readableName: _ember["default"].computed("category", function () {
      if (this.get("category") === "general") {
        return "General Alert";
      } else if (this.get("category") === "medication") {
        return "Medication Alert";
      } else if (this.get("category") === "spirometry") {
        return "Spirometry Request";
      }
      return "Unknown";
    })
  });
});