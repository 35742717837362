define("map-ui/routes/patients/schedule", ["exports", "ember", "map-ui/mixins/allowed-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], {
    layout: "fullscreen",
    isFullscreenActive: true,

    model: function model(params) {
      return this.store.findRecord("patient", params.patient_id, {
        reload: true
      });
    }
  });
});