define("map-ui/components/input/textarea-field", ["exports", "map-ui/components/input/input-field", "ember"], function (exports, _mapUiComponentsInputInputField, _ember) {
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = _mapUiComponentsInputInputField["default"].extend({
    classNames: ["input", "textarea"],

    testId: computed("placeholder", function () {
      return get(this, "placeholder").dasherize();
    })
  });
});