define("map-ui/components/patient/alert/alert-row", ["exports", "ember", "ember-can"], function (exports, _ember, _emberCan) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var service = _ember["default"].inject.service;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    eventBus: service(),
    flash: service(),
    store: service(),

    classNames: ["alert-row", "card", "card--sub"],
    classNameBindings: ["isOpen:alert-row__open"],

    isOpen: false,
    isDeleting: false,

    ability: _emberCan.computed.ability("alert", "model"),
    canEdit: computed.oneWay("ability.canEdit"),

    isOpenStatusChanged: _ember["default"].observer("isOpen", function () {
      if (this.get("isOpen")) {
        this.get("eventBus").publish("alertRowOpened", get(this, "model"));
      } else {
        this.get("eventBus").publish("alertRowClosed", get(this, "model"));
      }
    }),

    didInsertElement: function didInsertElement() {
      this.get("eventBus").subscribe("alertRowOpened", this, "onAlertRowOpened");

      if (get(this, "model.isNew")) {
        set(this, "isOpen", true);
      }
    },

    willDestroyElement: function willDestroyElement() {
      this.get("eventBus").unsubscribe("alertRowOpened");
    },

    onAlertRowOpened: function onAlertRowOpened(alert) {
      if (alert.id !== get(this, "model.id")) {
        if (this.get("model.hasDirtyAttributes")) {
          get(this, "flash").warning("Alert was not saved. Please press save before closing.");
          this.get("model").rollbackAttributes();
        }
        if (this.get("isOpen")) {
          this.set("isOpen", false);
        }
      }
    },

    actions: {
      toggle: function toggle() {
        this.toggleProperty("isOpen");
      },
      save: function save() {
        var _this = this;

        get(this, "model").validate().then(function () {
          get(_this, "model").save().then(function () {
            get(_this, "flash").success("Alert saved!");
            set(_this, "isOpen", false);
          })["catch"](function () {
            get(_this, "flash").danger("Unable to save alert.");
          });
        })["catch"](function () {
          set(_this, "errors", get(_this, "model").get("errors"));
        });
      },
      rollback: function rollback() {
        get(this, "model").rollbackAttributes();
      },
      confirmDelete: function confirmDelete() {
        get(this, "model").destroyRecord();
      },
      cancelDelete: function cancelDelete() {
        set(this, "isDeleting", false);
      },
      "delete": function _delete() {
        set(this, "isDeleting", true);
      }
    }
  });
});