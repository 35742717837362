define("map-ui/components/flash/flash-manager", ["exports", "ember"], function (exports, _ember) {
  var service = _ember["default"].inject.service;
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({
    flash: service(),
    classNames: ["flash-container flash-bottom-left"],
    attributeBindings: ["ariaLive:aria-live", "role"],

    ariaLive: "polite",
    role: "alert"
  });
});