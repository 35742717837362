define("map-ui/services/event-bus", ["exports", "ember"], function (exports, _ember) {
  var Service = _ember["default"].Service;
  var Evented = _ember["default"].Evented;
  exports["default"] = Service.extend(Evented, {
    publish: function publish() {
      return this.trigger.apply(this, arguments);
    },
    subscribe: function subscribe() {
      return this.on.apply(this, arguments);
    },
    unsubscribe: function unsubscribe() {
      return this.off.apply(this, arguments);
    }
  });
});