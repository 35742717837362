define("map-ui/components/input/weight-field", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    pounts: null,

    calculateStandard: (function () {
      var kilograms = get(this, "value");
      if (!kilograms) {
        return;
      }
      kilograms = parseFloat(kilograms);
      set(this, "pounds", Math.ceil(kilograms * 2.2046));
    }).on("init"),

    actions: {
      calculateMetric: function calculateMetric() {
        var pounds = get(this, "pounds") ? parseFloat(get(this, "pounds")) : 0.0;
        set(this, "value", pounds * 0.4535);
      }
    }
  });
});