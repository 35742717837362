define('map-ui/helpers/is-equal-month', ['exports', 'ember-date-components/helpers/is-equal-month'], function (exports, _emberDateComponentsHelpersIsEqualMonth) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualMonth['default'];
    }
  });
  Object.defineProperty(exports, 'isEqualMonth', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersIsEqualMonth.isEqualMonth;
    }
  });
});