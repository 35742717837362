define("map-ui/models/patient", ["exports", "ember-data", "map-ui/mixins/validators/patient"], function (exports, _emberData, _mapUiMixinsValidatorsPatient) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  var hasMany = _emberData["default"].hasMany;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsPatient["default"], {
    patientCode: attr("string"),
    careCenter: attr("boolean"),
    height: attr("number"),
    weight: attr("number"),
    ethnicity: attr("string", { defaultValue: "Caucasian" }),
    birthDate: attr("string"),
    gender: attr("string"),

    primaryContact: belongsTo("person"),
    secondaryContact: belongsTo("person"),
    loginUser: belongsTo("user"),
    careGiver: belongsTo("user"),

    alerts: hasMany("alerts", { async: true }),
    chats: hasMany("chats", { async: true }),
    carePlans: hasMany("care-plans", { async: true }),
    devices: hasMany("devices", { async: true }),
    activeAlertJobs: hasMany("alertJobs", { async: true }),
    graphCollections: hasMany("graph-collections", { async: true })
  });
});