define("map-ui/routes/reports/me", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    sessionUser: _ember["default"].inject.service(),

    model: function model() {
      return this.get("sessionUser.user.client.graphCollections");
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var graphId = controller.get("graphId");

      if (graphId === undefined) {
        graphId = model.get("firstObject.id");
      }

      controller.setProperties({ graphId: graphId });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          graphId: undefined,
          selectedGraphCollection: undefined
        });
      }
    }
  });
});