define("map-ui/components/input/select-field", ["exports", "map-ui/components/input/input-field"], function (exports, _mapUiComponentsInputInputField) {
  exports["default"] = _mapUiComponentsInputInputField["default"].extend({
    value: null,

    isFlat: false,
    options: null,
    showEmpty: true,

    actions: {
      onchange: function onchange(selection) {
        this.set("value", selection);
        if (this.attrs.onchange) {
          this.attrs.onchange(selection);
        }
      }
    }
  });
});