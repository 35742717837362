define("map-ui/components/patient/device/device-token-generator", ["exports", "ember"], function (exports, _ember) {
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    classNames: ["device-token-generator"],

    classNameBindings: ["isShowingToken:device-token-generator--open:device-token-generator--closed"],

    flash: service(),
    ajax: service(),

    token: null,
    isShowingToken: computed.notEmpty("token"),
    notShowingToken: computed.not("isShowingToken"),

    qrCodeUrl: _ember["default"].computed("token", function () {
      return "https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=" + get(this, "token");
    }),

    actions: {
      newDeviceToken: function newDeviceToken() {
        var _this = this;

        get(this, "ajax").post("device_tokens", {
          processData: false,
          contentType: "application/json",
          dataType: "text",
          data: JSON.stringify({
            data: {
              attributes: {},
              relationships: {
                user: {
                  data: {
                    id: get(this, "model.loginUser.id")
                  }
                }
              }
            }
          })
        }).then(function (result) {
          var token = JSON.parse(result).data.attributes.key;
          if (token) {
            set(_this, "token", token);
          }
        })["catch"](function () {
          get(_this, "flash").error("Unable to change password");
        });
      },
      hideToken: function hideToken() {
        set(this, "token", null);
      }
    }
  });
});