define("map-ui/components/patient/chat/quick-message-field", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    flash: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    content: null,

    actions: {
      createChat: function createChat() {
        var _this = this;

        var content = this.get("content");
        this.set("content");
        this.get("store").createRecord("chat", {
          content: content,
          patient: this.get("model")
        }).save().then(function () {
          _this.get("flash").success("Message sent!");
        })["catch"](function () {
          _this.get("flash").danger("Unable to delivery message!");
        });
      }
    }
  });
});