define("map-ui/transitions", ["exports", "map-ui/config/environment"], function (exports, _mapUiConfigEnvironment) {

  function getDuration() {
    return _mapUiConfigEnvironment["default"].environment === "test" ? 0 : 250;
  }

  exports["default"] = function () {
    this.setDefault({ duration: getDuration() });

    this.transition(this.fromRoute("patients"), this.toRoute("drugs"), this.use("toLeft"));
    this.transition(this.fromRoute("patients"), this.toRoute("me"), this.use("toLeft"));
    this.transition(this.fromRoute("drugs"), this.toRoute("me"), this.use("toLeft"));
    this.transition(this.fromRoute("patients"), this.toRoute("users"), this.use("toLeft"));
    this.transition(this.fromRoute("drugs"), this.toRoute("users"), this.use("toLeft"));
    this.transition(this.fromRoute("users"), this.toRoute("me"), this.use("toLeft"));
    this.transition(this.fromRoute("drugs"), this.toRoute("patients"), this.use("toRight"));
    this.transition(this.fromRoute("me"), this.toRoute("patients"), this.use("toRight"));
    this.transition(this.fromRoute("me"), this.toRoute("drugs"), this.use("toRight"));
    this.transition(this.fromRoute("users"), this.toRoute("patients"), this.use("toRight"));
    this.transition(this.fromRoute("users"), this.toRoute("drugs"), this.use("toRight"));
    this.transition(this.fromRoute("me"), this.toRoute("users"), this.use("toRight"));
    this.transition(this.fromRoute("clients"), this.toRoute("users"), this.use("toRight"));
    this.transition(this.fromRoute("clients"), this.toRoute("drugs"), this.use("toRight"));
    this.transition(this.fromRoute("clients"), this.toRoute("users"), this.use("toRight"));
    this.transition(this.fromRoute("clients"), this.toRoute("patients"), this.use("toRight"));
    this.transition(this.fromRoute("clients"), this.toRoute("me"), this.use("toLeft"));
    this.transition(this.fromRoute("me"), this.toRoute("clients"), this.use("toRight"));
    this.transition(this.fromRoute("users"), this.toRoute("clients"), this.use("toLeft"));
    this.transition(this.fromRoute("drugs"), this.toRoute("clients"), this.use("toLeft"));
    this.transition(this.fromRoute("patients"), this.toRoute("clients"), this.use("toLeft"));
    /*
     * Tab Transitions
     */
    this.transition(this.hasClass("tab-patient"), this.toValue(true), this.use("toUp"), this.reverse("toUp"));
    this.transition(this.hasClass("tab-primaryContact"), this.toValue(true), this.use("toUp"), this.reverse("toUp"));
    this.transition(this.hasClass("tab-secondaryContact"), this.toValue(true), this.use("toUp"), this.reverse("toUp"));
    this.transition(this.hasClass("tab-alerts"), this.toValue(true), this.use("toUp"), this.reverse("toUp"));
    this.transition(this.hasClass("tab-stats"), this.toValue(true), this.use("toUp"), this.reverse("toUp"));
    /*
     * Generic Transitions
     */
    this.transition(this.hasClass("fade"), this.toValue(true), this.use("crossFade"));
    /*
     * Patient Dashboard Transitions
     */
    this.transition(this.fromRoute("patients.show.index"), this.toRoute("patients.show.alerts"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("patients.show.index"), this.toRoute("patients.show.statistics"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("patients.show.index"), this.toRoute("patients.show.edit"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("patients.show.alerts"), this.toRoute("patients.show.statistics"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("patients.show.alerts"), this.toRoute("patients.show.edit"), this.use("toLeft"), this.reverse("toRight"));
    this.transition(this.fromRoute("patients.show.statistics"), this.toRoute("patients.show.edit"), this.use("toLeft"), this.reverse("toRight"));
  };
});