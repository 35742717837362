define("map-ui/components/patient/show/patient-adherence-dash", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  exports["default"] = Component.extend({
    ajax: service(),

    adherenceGraphObject: null,

    display: computed("adherenceGraphObject", function () {
      var graphObject = get(this, "adherenceGraphObject");
      // Check for the existence of the graph object and make sure it has at least 1 value
      if (graphObject === null || graphObject.values.map(function (a) {
        return a.value;
      }).reduce(function (a, b) {
        return a + b;
      }) < 1) {
        return false;
      }
      return true;
    }),

    setGraphObject: (function () {
      var _this = this;

      get(this, "ajax").request("graph_collections/total_adherence?patient_id=" + get(this, "model.id")).then(function (resp) {
        set(_this, "adherenceGraphObject", resp.included[0].attributes);
      });
    }).on("init")
  });
});