define("map-ui/router", ["exports", "ember", "map-ui/config/environment"], function (exports, _ember, _mapUiConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _mapUiConfigEnvironment["default"].locationType,
    rootURL: _mapUiConfigEnvironment["default"].rootURL
  });

  Router.map(function () {
    this.route("login");
    this.route("terms");
    this.route("patients", function () {
      this.route("show", { path: ":patient_id" }, function () {
        this.route("alerts");
        this.route("chats");
        this.route("statistics");
        this.route("edit");
        this.route("devices");
        this.route("plans");
      });
      this.route("create");
      this.route("schedule", { path: ":patient_id/schedule" });
    });
    this.route("drugs", { path: "/medications" }, function () {
      this.route("edit", { path: ":drug_id/edit" });
      this.route("create");
    });
    this.route("users", function () {
      this.route("edit", { path: ":user_id/edit" });
      this.route("create");
    });
    this.route("forgot", function () {
      this.route("token");
    });
    this.route("me");
    this.route("clients", function () {
      this.route("edit", { path: ":client_id/edit" });
      this.route("create");
    });
    this.route("application-not-found", { path: "/*path" });
    this.route("reports", function () {
      this.route("me");
      this.route("show", { path: ":client_id" });
    });
  });

  exports["default"] = Router;
});