define("map-ui/services/ajax", ["exports", "ember-ajax/services/ajax", "map-ui/config/environment", "ember"], function (exports, _emberAjaxServicesAjax, _mapUiConfigEnvironment, _ember) {
  var get = _ember["default"].get;
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = _emberAjaxServicesAjax["default"].extend({
    host: _mapUiConfigEnvironment["default"].host,
    namespace: "v2",

    session: service(),

    headers: computed("session.data.authenticated.token", function () {
      var token = get(this, "session.data.authenticated.token");
      var headers = [];
      if (!isEmpty(token)) {
        headers["Authorization"] = "Bearer " + token;
      }
      return headers;
    })
  });
});