define("map-ui/components/patient/show/patient-alerts-dash", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    model: null,

    spiromertryAlerts: computed.filterBy("model.alerts", "category", "spirometry"),
    medicationAlerts: computed.filterBy("model.alerts", "category", "medication"),
    generalAlerts: computed.filterBy("model.alerts", "category", "general")
  });
});