define("map-ui/components/input/height-fields", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    value: null,
    feet: null,
    inches: null,

    calculateStandard: (function () {
      var centimeters = get(this, "value");
      if (!centimeters) {
        return;
      }
      centimeters = parseFloat(centimeters);
      var inches = centimeters / 2.54;
      set(this, "feet", Math.floor(inches / 12));
      set(this, "inches", Math.floor(inches % 12));
    }).on("init"),

    actions: {
      calculateMetric: function calculateMetric() {
        var feet = get(this, "feet") ? parseFloat(get(this, "feet")) : 0.0;
        var inches = get(this, "inches") ? parseFloat(get(this, "inches")) : 0.0;
        set(this, "value", (feet * 12 + inches) * 2.54);

        var value = get(this, "value");
        this.sendAction("onHeightChange", value);
      }
    }
  });
});