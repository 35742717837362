define("map-ui/controllers/users/create", ["exports", "ember", "ember-can"], function (exports, _ember, _emberCan) {
  var Controller = _ember["default"].Controller;
  var service = _ember["default"].inject.service;
  exports["default"] = Controller.extend({
    flash: service(),

    roleOptions: ["Care Giver", "Administrator"],

    ability: _emberCan.computed.ability("user", "model")
  });
});