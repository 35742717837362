define("map-ui/models/drug", ["exports", "ember-data", "map-ui/mixins/validators/drug"], function (exports, _emberData, _mapUiMixinsValidatorsDrug) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsDrug["default"], {
    name: attr("string"),
    nameNormalized: attr("string"),
    dosageForm: attr("string"),
    strength: attr("string"),
    strengthMeasure: attr("string"),
    color: attr("string"),
    imageUrl: attr("string"),
    nationalDrugCode: attr("string"),
    custom: attr("boolean")
  });
});