define("map-ui/mixins/validators/alert", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    validations: {
      "drug.id": {
        presence: {
          "if": function _if(object, validator) {
            if (object.get("category") === "medication") {
              return true;
            }
            return false;
          }
        }
      }
    }
  });
});