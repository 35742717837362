define("map-ui/components/flash/flash-message", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    classNames: ["flash"],
    classNameBindings: ["flashType"],

    flashType: computed("flash.type", function () {
      var flashType = get(this, "flash.type");
      return "flash-" + flashType;
    })
  });
});