define("map-ui/adapters/application", ["exports", "active-model-adapter", "ember-simple-auth/mixins/data-adapter-mixin", "map-ui/config/environment", "ember"], function (exports, _activeModelAdapter, _emberSimpleAuthMixinsDataAdapterMixin, _mapUiConfigEnvironment, _ember) {
  var String = _ember["default"].String;
  exports["default"] = _activeModelAdapter["default"].extend(_emberSimpleAuthMixinsDataAdapterMixin["default"], {
    host: "" + _mapUiConfigEnvironment["default"].host,
    authorizer: "authorizer:map",
    namespace: "v2",

    pathForType: function pathForType(type) {
      return String.pluralize(String.underscore(type));
    },

    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = _ember["default"].get(snapshot, "adapterOptions.query");
      if (query) {
        url += "?" + _ember["default"].$.param(query); // assumes no query params are present already
      }
      return url;
    }
  });
});