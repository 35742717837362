define("map-ui/components/graph-collection-viewer", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),

    includeUnacceptable: false,
    selectedGraphCollection: null,

    startDate: moment().subtract(7, "days"),
    endDate: moment(),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadGraph();
    },

    graphIncludes: _ember["default"].computed("includeUnacceptable", function () {
      if (this.get("includeUnacceptable")) {
        return ["unacceptable"];
      }
    }),

    loadGraph: function loadGraph() {
      var _this = this;

      if (this.get("graphId") !== undefined) {
        this.get("store").findRecord("graph-collection", this.get("graphId"), {
          adapterOptions: {
            query: {
              include_unacceptable: this.get("includeUnacceptable"),
              start_date: this.get("startDate").format("YYYY-MM-DD"),
              end_date: this.get("endDate").format("YYYY-MM-DD"),
              per_page: 0
            }
          }
        }).then(function (graph) {
          _this.set("selectedGraphCollection", graph);
        });
      }
    },

    datePickerOptions: function datePickerOptions() {
      return ["clear", "today", "last7days", "last30Days", "lastYear"];
    },

    dateRangeArray: _ember["default"].computed("startDate", "endDate", function () {
      return [this.get("startDate"), this.get("endDate")];
    }),

    actions: {
      graphTypeSelected: function graphTypeSelected(id) {
        this.set("graphId", id);
        this.loadGraph();
      },
      graphTypeChanged: function graphTypeChanged() {
        this.loadGraph();
      },
      updateDateRange: function updateDateRange(result) {
        if (result[0] !== null && result[1] !== null) {
          this.set("startDate", result[0]);
          this.set("endDate", result[1]);
          this.loadGraph();
        }
      }
    }
  });
});