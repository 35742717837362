define("map-ui/components/drug/drug-row", ["exports", "ember", "ember-can"], function (exports, _ember, _emberCan) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_emberCan.CanMixin, {
    tagName: "tr",

    testId: computed(function () {
      return ["qa-drug--" + get(this, "model.id")];
    }),

    isBeingDeleted: false,

    actions: {
      startDelete: function startDelete() {
        set(this, "isBeingDeleted", true);
      },
      confirmDelete: function confirmDelete() {
        get(this, "model").destroyRecord();
      },
      cancelDelete: function cancelDelete() {
        set(this, "isBeingDeleted", false);
      }
    }
  });
});