define("map-ui/services/flash", ["exports", "ember", "map-ui/utils/flash-message"], function (exports, _ember, _mapUiUtilsFlashMessage) {
  var Service = _ember["default"].Service;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var run = _ember["default"].run;
  var assert = _ember["default"].assert;
  var A = _ember["default"].A;
  exports["default"] = Service.extend({
    queue: A([]),
    isEmpty: computed.equal("queue.length", 0),

    defaultTimeout: 3500,
    defaultErrorTimeout: 9000,

    success: function success(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? get(this, "defaultTimeout") : arguments[1];

      this.add(message, "success", timeout);
    },

    info: function info(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? get(this, "defaultTimeout") : arguments[1];

      this.add(message, "info", timeout);
    },

    warning: function warning(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? get(this, "defaultErrorTimeout") : arguments[1];

      this.add(message, "warning", timeout);
    },

    error: function error(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? get(this, "defaultErrorTimeout") : arguments[1];

      this.add(message, "error", timeout);
    },

    danger: function danger(message) {
      var timeout = arguments.length <= 1 || arguments[1] === undefined ? get(this, "defaultErrorTimeout") : arguments[1];

      this.error(message, timeout);
    },

    addMessage: function addMessage(message) {
      var type = arguments.length <= 1 || arguments[1] === undefined ? "info" : arguments[1];
      var timeout = arguments.length <= 2 || arguments[2] === undefined ? get(this, "defaultTimeout") : arguments[2];

      this.add(message, type, timeout);
    },

    clearMessages: function clearMessages() {
      var flashes = get(this, "queue");

      run.next(this, function () {
        flashes.clear();
      });
    },

    add: function add(message, type, timeout) {
      var flashes = get(this, "queue");
      var flash = this.newFlashMessage(this, message, type, timeout);

      run.next(this, function () {
        flashes.pushObject(flash);
      });

      return true;
    },

    newFlashMessage: function newFlashMessage(service, message, type, timeout) {
      assert("Must pass a valid flash service", service);
      assert("Must pass a valid flash message", message);

      type = typeof type === "undefined" ? "info" : type;
      timeout = timeout || get(this, "defaultTimeout");

      return _mapUiUtilsFlashMessage["default"].create({
        type: type,
        message: message,
        timeout: timeout,
        flashService: service
      });
    }
  });
});