define("map-ui/mixins/validators/patient", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  var computed = _ember["default"].computed;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    person: computed.alias("loginUser.person"),

    validations: {
      "person.firstName": {
        presence: true
      },
      ethnicity: {
        presence: true
      },
      height: {
        presence: true
      },
      weight: {
        presence: true
      },
      "person.birthDate": {
        presence: true
      },
      "person.gender": {
        presence: true
      },
      "person.lastName": {
        presence: true
      },
      "person.timeZone": {
        presence: true
      },
      "loginUser.username": {
        presence: true,
        length: {
          minimum: 5
        }
      },
      "loginUser.password": {
        confirmation: { message: "passwords must match" },
        presence: {
          "if": "isNew"
        },
        length: {
          minimum: 8,
          "if": function _if(object, validator) {
            if (object.get("loginUser.password") !== undefined) {
              return true;
            }
            return false;
          }
        },
        format: {
          "with": /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/,
          message: "must contain one capital letter, one lowercase letter, one number, and one special character.",
          "if": function _if(object, validator) {
            if (object.get("loginUser.password") !== undefined) {
              return true;
            }
            return false;
          }
        }
      }
    }
  });
});