define("map-ui/mixins/search", ["exports", "ember"], function (exports, _ember) {
  var Mixin = _ember["default"].Mixin;
  var set = _ember["default"].set;
  var observer = _ember["default"].observer;
  exports["default"] = Mixin.create({
    queryParams: ["query", "page"],

    page: 1,
    query: null,

    resetPageObserver: observer("query", function () {
      set(this, "page", 1);
    })
  });
});