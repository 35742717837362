define("map-ui/components/tab/tab-wrap", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    currentTab: null,
    tabName: null,
    isHidden: computed("currentTab", "tabName", function () {
      if (get(this, "currentTab").split("-")[0] === get(this, "tabName")) {
        return false;
      }
      if (get(this, "currentTab").split("-")[1] === get(this, "tabName")) {
        return false;
      }
      return true;
    }),
    isShowing: computed.not("isHidden")
  });
});