define("map-ui/mixins/save-relationships", ["exports", "ember"], function (exports, _ember) {
  var Mixin = _ember["default"].Mixin;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var String = _ember["default"].String;
  var GUID_KEY = _ember["default"].GUID_KEY;
  exports["default"] = Mixin.create({
    serializeRelationship: function serializeRelationship(snapshot, data, rel) {
      var relKind = rel.kind;
      var relKey = rel.key;

      if (get(this, "attrs." + relKey + ".serialize") === true) {
        data.relationships = data.relationships || {};
        var key = this.keyForRelationship(relKey, relKind, "serialize");
        data.relationships[key] = data.relationships[key] || {};

        if (relKind === "belongsTo") {
          data.relationships[key].data = this.serializeRecord(snapshot.belongsTo(relKey));
        }

        if (relKind === "hasMany") {
          if (snapshot.hasMany(relKey)) {
            data.relationships[key].data = snapshot.hasMany(relKey).map(this.serializeRecord.bind(this));
          }
        }
      }
    },

    serialize: function serialize(snapshot, options) {
      if (!(options && options.__isSaveRelationshipsMixinCallback)) {
        set(this, "_visitedRecordIds", {});
      }
      return this._super.apply(this, arguments);
    },

    _visitedRecordIds: {},

    serializeRecord: function serializeRecord(obj) {
      if (!obj) {
        return null;
      }

      var serialized = obj.serialize({
        __isSaveRelationshipsMixinCallback: true
      });

      if (obj.id) {
        serialized.data.id = obj.id;
        get(this, "_visitedRecordIds")[obj.id] = {};
      } else {
        if (!serialized.data.attributes) {
          serialized.data.attributes = {};
        }
        serialized.data.attributes.transientid = obj.record.get("_internalModel")[GUID_KEY];
        get(this, "_visitedRecordIds")[serialized.data.attributes.transientid] = {};
      }

      for (var relationshipId in serialized.data.relationships) {
        if (get(this, "_visitedRecordIds")[relationshipId]) {
          delete serialized.data.relationships[relationshipId];
        }
      }

      if (serialized.data.relationships === {}) {
        delete serialized.data.relationships;
      }

      // // do not allow embedded relationships
      // delete serialized.data.relationships;

      return serialized.data;
    },

    serializeHasMany: function serializeHasMany() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },

    serializeBelongsTo: function serializeBelongsTo() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },

    updateRecord: function updateRecord(json, store) {
      if (json.attributes !== undefined && json.attributes.transientid !== undefined) {
        var record = store.peekAll(json.type).filterBy("currentState.stateName", "root.loaded.created.uncommitted").findBy("_internalModel." + GUID_KEY, json.attributes.transientid);

        if (record) {
          // record.unloadRecord();
          record.set("id", json.id);
          record._internalModel.flushChangedAttributes();
          record._internalModel.adapterWillCommit();
          store.didSaveRecord(record._internalModel);
          // store.push({ data: json });
        }
      }

      return json;
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, modelName, obj) {
      var _this = this;

      var rels = obj.data.relationships || [];

      var included = {};
      if (obj.included) {
        included = obj.included.reduce(function (prev, current) {
          prev[current.id] = current;
          return prev;
        }, {});
      }

      Object.keys(rels).forEach(function (rel) {
        var relationshipData = rels[rel].data;
        if (relationshipData) {
          _this.normalizeRelationship(relationshipData, store, included);
        }
      });

      return this._super(store, modelName, obj);
    },

    normalizeRelationship: function normalizeRelationship(relationshipData, store, included) {
      var _this2 = this;

      if (Array.isArray(relationshipData)) {
        // hasMany
        relationshipData = relationshipData.map(function (json) {
          var includedData = included[json.id];
          if (includedData) {
            json = includedData;
          }
          var internalRelationships = json.relationships;
          if (internalRelationships !== undefined) {
            Object.keys(internalRelationships).forEach(function (rel) {
              _this2.normalizeRelationship(internalRelationships[rel].data, store, included);
            });
          }
          json.type = String.singularize(json.type);
          _this2.updateRecord(json, store);
        });
      } else {
        // belongsTo
        if (relationshipData !== undefined && relationshipData !== null) {
          (function () {
            var internalRelationships = relationshipData.relationships;
            if (internalRelationships !== undefined && internalRelationships !== null) {
              Object.keys(internalRelationships).forEach(function (rel) {
                _this2.normalizeRelationship(internalRelationships[rel].data, store, included);
              });
            }
            relationshipData.type = String.singularize(relationshipData.type);
            relationshipData = _this2.updateRecord(relationshipData, store);
          })();
        }
      }
    }
  });
});