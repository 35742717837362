define("map-ui/serializers/patient", ["exports", "map-ui/serializers/application", "map-ui/mixins/save-relationships", "ember-data/adapters/errors"], function (exports, _mapUiSerializersApplication, _mapUiMixinsSaveRelationships, _emberDataAdaptersErrors) {
  exports["default"] = _mapUiSerializersApplication["default"].extend(_mapUiMixinsSaveRelationships["default"], {
    attrs: {
      primaryContact: {
        serialize: true
      },
      secondaryContact: {
        serialize: true
      },
      loginUser: {
        serialize: true
      },
      carePlans: {
        serialize: true
      }
    },

    // TODO: Clean this method using proper error handling
    // Currently the API isn't returning proper JSON API errors so this isn't working anyways.
    // Turns out even if you return proper jsonapi errors ember data 2.9 is broken
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      return {};
    }
  });
});