define('map-ui/instance-initializers/bugsnag', ['exports', 'ember', 'map-ui/config/environment', 'ember-cli-bugsnag/utils/errors', 'map-ui/utils/bugsnag', 'bugsnag'], function (exports, _ember, _mapUiConfigEnvironment, _emberCliBugsnagUtilsErrors, _mapUiUtilsBugsnag, _bugsnag) {
  exports.initialize = initialize;
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;

  function initialize(instance) {
    var _this = this;

    if (_bugsnag['default'].apiKey === undefined) {
      return;
    }
    var currentEnv = _mapUiConfigEnvironment['default'].environment;
    var bugsnagConfig = _mapUiConfigEnvironment['default'].bugsnag || {};
    var releaseStage = bugsnagConfig.releaseStage || currentEnv;

    if (currentEnv !== 'test' && _bugsnag['default'].notifyReleaseStages.indexOf(releaseStage) !== -1) {
      var owner = instance.lookup ? instance : instance.container;
      var router = owner.lookup('router:main');

      setProperties(this, {
        owner: owner,
        router: router
      });

      _ember['default'].onerror = function (error) {
        return _this._onError(error);
      };

      router.didTransition = this._didTransition();
    }
  }

  exports['default'] = {
    name: 'bugsnag-error-service',

    initialize: initialize,

    _didTransition: function _didTransition() {
      var router = get(this, 'router');
      var originalDidTransition = router.didTransition || function () {};

      return function () {
        _bugsnag['default'].refresh();
        return originalDidTransition.apply(this, arguments);
      };
    },

    _onError: function _onError(error) {
      this._setContext();
      this._setUser();
      this._setNotifyException(error);

      /* eslint-disable no-console */
      console.error(error.stack);
      /* eslint-enable no-console */
    },

    _setContext: function _setContext() {
      var router = get(this, 'router');
      _bugsnag['default'].context = (0, _emberCliBugsnagUtilsErrors.getContext)(router);
    },

    _setNotifyException: function _setNotifyException(error) {
      var owner = get(this, 'owner');
      var metaData = _mapUiUtilsBugsnag.getMetaData ? _mapUiUtilsBugsnag.getMetaData(error, owner) : {};
      _bugsnag['default'].notifyException(error, null, metaData);
    },

    _setUser: function _setUser() {
      var owner = get(this, 'owner');
      if (_mapUiUtilsBugsnag.getUser) {
        var user = _mapUiUtilsBugsnag.getUser(owner);
        _bugsnag['default'].user = user;
      }
    }
  };
});