define("map-ui/adapters/user", ["exports", "map-ui/adapters/application"], function (exports, _mapUiAdaptersApplication) {
  exports["default"] = _mapUiAdaptersApplication["default"].extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return this._super.apply(this, arguments) + "/me";
      }

      return this._super.apply(this, arguments);
    }
  });
});