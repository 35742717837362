define("map-ui/helpers/percentage-of", ["exports", "ember"], function (exports, _ember) {
  exports.percentageOf = percentageOf;

  function percentageOf(params) {
    var result = (params[0] / params[1] * 100).toFixed(1);
    if (isNaN(result)) {
      return "--";
    }
    return result + "%";
  }

  exports["default"] = _ember["default"].Helper.helper(percentageOf);
});