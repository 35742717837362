define("map-ui/controllers/patients/show/alerts", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  exports["default"] = Controller.extend({
    eventBus: service(),

    alertsReversed: computed("model.alerts", function () {
      return get(this, "model.alerts").reverseObjects();
    }),

    isShowingSelector: false,

    actions: {
      showSelector: function showSelector() {
        this.set("isShowingSelector", true);
      },
      hideSelector: function hideSelector() {
        this.set("isShowingSelector", false);
      },
      createAlert: function createAlert(type) {
        var alert = get(this, "store").createRecord("alert", {
          category: type,
          patient: get(this, "model"),
          offsetMinutes: 60
        });
        this.get("eventBus").publish("alertRowOpened", alert);
      }
    }
  });
});