define("map-ui/components/table-pagination", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    classNames: ["pagination", "d--xs--flex", "d--xs--c"],
    page: 1,

    nextPage: computed("page", function () {
      return get(this, "page") + 1;
    }),

    previousPage: computed("page", function () {
      return get(this, "page") - 1;
    }),

    hasPrevious: computed.gt("page", 1),

    hasNext: computed("page", "pages", function () {
      return get(this, "page") < get(this, "pages");
    })
  });
});