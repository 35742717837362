define("map-ui/components/input/address-fields", ["exports", "ember"], function (exports, _ember) {
  var get = _ember["default"].get;
  exports["default"] = _ember["default"].Component.extend({
    isShowingDetails: false,

    line1Label: _ember["default"].computed("isShowingDetails", function () {
      if (get(this, "isShowingDetails")) {
        return "Line 1";
      }
      return "Address";
    }),

    actions: {
      focusIn: function focusIn() {
        this.set("isShowingDetails", true);
      }
    }
  });
});