define("map-ui/routes/patients/create", ["exports", "ember", "map-ui/mixins/allowed-route-mixin", "map-ui/mixins/reset-model-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin, _mapUiMixinsResetModelRouteMixin) {
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], _mapUiMixinsResetModelRouteMixin["default"], {
    ability: "create patient",

    model: function model() {
      var patient = get(this, "store").createRecord("patient");

      patient.set("primaryContact", get(this, "store").createRecord("person", {
        address: get(this, "store").createRecord("address")
      }));

      patient.set("secondaryContact", get(this, "store").createRecord("person", {
        address: get(this, "store").createRecord("address")
      }));

      patient.set("loginUser", get(this, "store").createRecord("user", {
        person: get(this, "store").createRecord("person", {
          address: get(this, "store").createRecord("address")
        })
      }));

      return patient;
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set("model", null);
        controller.set("errors", null);
      }
    }
  });
});