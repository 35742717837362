define('map-ui/helpers/date-picker-day-classes', ['exports', 'ember-date-components/helpers/date-picker-day-classes'], function (exports, _emberDateComponentsHelpersDatePickerDayClasses) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersDatePickerDayClasses['default'];
    }
  });
  Object.defineProperty(exports, 'datePickerDayClasses', {
    enumerable: true,
    get: function get() {
      return _emberDateComponentsHelpersDatePickerDayClasses.datePickerDayClasses;
    }
  });
});