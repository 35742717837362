define("map-ui/utils/flash-message", ["exports", "ember"], function (exports, _ember) {
  var Object = _ember["default"].Object;
  var Evented = _ember["default"].Evented;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var run = _ember["default"].run;
  var getWithDefault = _ember["default"].getWithDefault;
  exports["default"] = Object.extend(Evented, {
    isSuccess: computed.equal("type", "success"),
    isInfo: computed.equal("type", "info"),
    isWarning: computed.equal("type", "warning"),
    isDanger: computed.equal("type", "danger"),

    defaultTimeout: computed.alias("flashService.defaultTimeout"),
    queue: computed.alias("flashService.queue"),

    destroyLater: (function () {
      var defaultTimeout = get(this, "defaultTimeout");
      var timeout = getWithDefault(this, "timeout", defaultTimeout);

      run.later(this, "destroyMessage", timeout);
    }).on("init"),

    destroyMessage: function destroyMessage() {
      this.destroy();
      if (get(this, "queue")) {
        get(this, "queue").removeObject(this);
      }
    }
  });
});