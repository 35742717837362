define("map-ui/components/drug/drug-details", ["exports", "ember", "ember-can"], function (exports, _ember, _emberCan) {
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var computed = _ember["default"].computed;
  var Component = _ember["default"].Component;
  var observer = _ember["default"].observer;
  exports["default"] = Component.extend(_emberCan.CanMixin, {
    model: null,

    dosageFormOptions: ["Tablet", "Liquid", "Ointment", "Spray", "Foam"],

    isEnabled: _ember["default"].computed(function () {
      return this.can("edit drug");
    }),

    isDisabled: computed.not("isEnabled"),

    nameChanged: observer("model.name", function () {
      set(this, "model.nameNormalized", get(this, "model.name"));
      set(this, "model.rxString", get(this, "model.name"));
    }),

    actions: {
      save: function save() {
        this.sendAction("save");
      },
      rollback: function rollback() {
        this.sendAction("rollback");
      }
    }
  });
});