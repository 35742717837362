define("map-ui/routes/patients/show/statistics", ["exports", "ember", "map-ui/mixins/allowed-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], {
    model: function model() {
      return this.modelFor("patients.show");
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var graphId = controller.get("graphId");

      if (graphId === undefined) {
        graphId = model.get("graphCollections.firstObject.id");
      }

      controller.setProperties({ graphId: graphId });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          graphId: undefined,
          selectedGraphCollection: undefined
        });
      }
    }
  });
});