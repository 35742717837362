define("map-ui/abilities/alert", ["exports", "ember", "ember-can", "map-ui/mixins/ability/has-role"], function (exports, _ember, _emberCan, _mapUiMixinsAbilityHasRole) {
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = _emberCan.Ability.extend(_mapUiMixinsAbilityHasRole["default"], {
    canShow: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver", "Reporter", "Patient");
    }),

    canCreate: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver");
    }),

    canEdit: computed("sessionUser.user", "model", function () {
      return this.hasAnyRole("Administrator", "Care Giver", "Patient");
    }),

    canAddMedication: computed("sessionUser.user", function () {
      return this.hasAnyRole("Administrator", "Care Giver");
    })
  });
});