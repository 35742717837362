define("map-ui/mixins/allowed-route-mixin", ["exports", "ember", "ember-can", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberCan, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports["default"] = _ember["default"].Mixin.create(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      if (this.get("session.isAuthenticated")) {
        if (this.get("ability")) {
          if (!this.can(this.get("ability"))) {
            this.transitionTo("me");
          }
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});