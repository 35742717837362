define("map-ui/components/patient/patient-details", ["exports", "ember", "ember-can", "map-ui/config/environment"], function (exports, _ember, _emberCan, _mapUiConfigEnvironment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var set = _ember["default"].set;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    ability: _emberCan.computed.ability("patient", "model"),
    canEdit: computed.oneWay("ability.canEdit"),
    cannotEdit: computed.not("canEdit"),
    currentTab: "patient",

    wasNew: computed(function () {
      return get(this, "model.isNew");
    }),

    hasFirstName: computed.notEmpty("model.loginUser.person.firstName"),
    hasLastName: computed.notEmpty("model.loginUser.person.lastName"),
    hasName: computed.and("hasFirstName", "hasLastName"),

    genderOptions: ["Unknown", "Male", "Female", "Other"],
    ethnicityOptions: ["Caucasian", "African American", "North East Asian", "South East Asian", "Other"],

    medicationImgUrl: computed(function () {
      return _mapUiConfigEnvironment["default"].cdn + "/images/common/icons_tablet.png";
    }),
    generalImgUrl: computed(function () {
      return _mapUiConfigEnvironment["default"].cdn + "/images/common/icons_alarm.png";
    }),
    spirometerImgUrl: computed(function () {
      return _mapUiConfigEnvironment["default"].cdn + "/images/common/icons_inhaler_spirometer_1.png";
    }),

    newAlertOpen: false,

    actions: {
      save: function save() {
        this.sendAction("save");
      },
      rollback: function rollback() {
        this.sendAction("rollback");
      },
      setTab: function setTab(tabName) {
        set(this, "currentTab", tabName);
      }
    }
  });
});