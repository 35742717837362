define("map-ui/components/alert/alert-selector", ["exports", "ember"], function (exports, _ember) {
  var set = _ember["default"].set;
  var get = _ember["default"].get;
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["autofill-selector"],

    types: [{
      id: "general",
      name: "General Alert"
    }, {
      id: "medication",
      name: "Medication Alert"
    }, {
      id: "spirometry",
      name: "Spirometer Alert"
    }],

    filteredTypes: _ember["default"].computed("searchQuery", function () {
      var searchQuery = this.get("searchQuery");

      if (!searchQuery) {
        searchQuery = "";
      }

      return this.get("types").filter(function (item, index, enumerable) {
        return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
      });
    }),

    showingPopup: true,

    actions: {
      showPopup: function showPopup() {
        set(this, "showingPopup", true);
      },
      hidePopup: function hidePopup() {
        var _this = this;

        _ember["default"].run.debounce(this, function () {
          if (!get(_this, "isDestroyed")) {
            set(_this, "showingPopup", false);
          }
        }, 300);
      },
      typeSelected: function typeSelected(type) {
        this.sendAction("createAlert", type);
        this.sendAction("hideSelector");
      },
      focusOutSearchBox: function focusOutSearchBox() {
        var _this2 = this;

        _ember["default"].run.debounce(this, function () {
          _this2.sendAction("hideSelector");
        }, 1000);
      }
    }
  });
});