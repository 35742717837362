define("map-ui/components/topnav-menu", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    tagName: "nav",
    classNames: ["topnav"],
    classNameBindings: ["isOpen:open"],
    sessionUser: service(),
    session: service(),

    isOpen: false,

    myProfileId: computed(function () {
      if (get(this, "sessionUser.user.patientId")) {
        return get(this, "sessionUser.user.patientId");
      }
    }),

    actions: {
      invalidateSession: function invalidateSession() {
        get(this, "session").invalidate();
      },
      toggleOpen: function toggleOpen() {
        this.toggleProperty("isOpen");
      }
    }
  });
});