define("map-ui/controllers/drugs/create", ["exports", "ember"], function (exports, _ember) {
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;
  exports["default"] = Controller.extend({
    flash: service(),

    actions: {
      save: function save() {
        var _this = this;

        get(this, "model").validate().then(function () {
          get(_this, "model").save().then(function () {
            get(_this, "flash").success("Medication saved!");
            _this.transitionToRoute("drugs");
          })["catch"](function () {
            get(_this, "flash").danger("Unable to save medication.");
          });
        })["catch"](function () {
          set(_this, "errors", get(_this, "model").get("errors"));
        });
      },
      rollback: function rollback() {
        get(this, "model").rollbackAttributes();
        this.transitionToRoute("drugs");
      }
    }
  });
});