define("map-ui/routes/patients/show", ["exports", "ember", "map-ui/mixins/allowed-route-mixin", "map-ui/mixins/reset-model-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin, _mapUiMixinsResetModelRouteMixin) {
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], _mapUiMixinsResetModelRouteMixin["default"], {
    model: function model(params) {
      var patient = this.store.findRecord("patient", params.patient_id, {
        reload: true,
        include: "graph-collections,care-plans,chats,chats.sending-user,chats.sending-user.person"
      });
      if (!patient.get("primaryContact")) {
        set(this, "primaryContact", get(this, "store").createRecord("person", {
          address: get(this, "store").createRecord("address")
        }));
      }
      if (!patient.get("secondaryContact")) {
        set(this, "secondaryContact", get(this, "store").createRecord("person", {
          address: get(this, "store").createRecord("address")
        }));
      }

      return patient;
    }
  });
});