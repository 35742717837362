define("map-ui/controllers/reports/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    actions: {
      graphTypeSelected: function graphTypeSelected(id) {
        this.set("graphId", id);
        this.loadGraph();
      },
      graphTypeChanged: function graphTypeChanged() {
        this.loadGraph();
      }
    }
  });
});