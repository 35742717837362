define("map-ui/components/person/person-details", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    model: null,
    prefix: "person",

    hasName: computed("model", function () {
      return !!get(this, "model.firstName") || !!get(this, "model.lastName");
    })
  });
});