define("map-ui/models/person", ["exports", "ember-data"], function (exports, _emberData) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  exports["default"] = Model.extend({
    firstName: attr("string"),
    middleName: attr("string"),
    lastName: attr("string"),
    gender: attr("string", { defaultValue: "Unknown" }),
    email: attr("string"),
    phone: attr("string"),
    birthDate: attr("date"),
    timeZone: attr("string", { defaultValue: "Eastern Time (US & Canada)" }),

    address: belongsTo("address", { async: true }),

    fullName: Ember.computed("firstName", "lastName", function () {
      return this.get("firstName") + " " + this.get("lastName");
    })
  });
});