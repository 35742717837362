define("map-ui/serializers/alert-schedule", ["exports", "ember-data", "map-ui/utils/cron-constructor", "map-ui/utils/cron-deconstructor"], function (exports, _emberData, _mapUiUtilsCronConstructor, _mapUiUtilsCronDeconstructor) {
  var JSONAPISerializer = _emberData["default"].JSONAPISerializer;
  exports["default"] = JSONAPISerializer.extend({
    normalize: function normalize(modelClass, resourceHash) {
      var cronString = resourceHash.attributes["cron-string"];

      var properties = _mapUiUtilsCronDeconstructor["default"].create().getPropertiesForString(cronString);

      resourceHash.attributes["time-of-day"] = properties.timeOfDay;
      resourceHash.attributes["monday-checkbox"] = properties.mondayCheckbox;
      resourceHash.attributes["tuesday-checkbox"] = properties.tuesdayCheckbox;
      resourceHash.attributes["wednesday-checkbox"] = properties.wednesdayCheckbox;
      resourceHash.attributes["thursday-checkbox"] = properties.thursdayCheckbox;
      resourceHash.attributes["friday-checkbox"] = properties.fridayCheckbox;
      resourceHash.attributes["saturday-checkbox"] = properties.saturdayCheckbox;
      resourceHash.attributes["sunday-checkbox"] = properties.sundayCheckbox;
      resourceHash.attributes["day-of-month"] = properties.dayOfMonth;
      resourceHash.attributes["hour"] = properties.hour;
      resourceHash.attributes["minutes"] = properties.minutes;
      resourceHash.attributes["period"] = properties.period;

      return this._super(modelClass, resourceHash);
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      json.data.attributes["cron-string"] = _mapUiUtilsCronConstructor["default"].create({
        typeOfScheduleField: json.data.attributes["schedule-type"],
        mondayCheckbox: json.data.attributes["monday-checkbox"],
        tuesdayCheckbox: json.data.attributes["tuesday-checkbox"],
        wednesdayCheckbox: json.data.attributes["wednesday-checkbox"],
        thursdayCheckbox: json.data.attributes["thursday-checkbox"],
        fridayCheckbox: json.data.attributes["friday-checkbox"],
        saturdayCheckbox: json.data.attributes["saturday-checkbox"],
        sundayCheckbox: json.data.attributes["sunday-checkbox"],
        dayOfMonth: json.data.attributes["day-of-month"],
        hour: json.data.attributes["hour"],
        minutes: json.data.attributes["minutes"],
        period: json.data.attributes["period"]
      }).toCronString();

      delete json.data.attributes["monday-checkbox"];
      delete json.data.attributes["tuesday-checkbox"];
      delete json.data.attributes["wednesday-checkbox"];
      delete json.data.attributes["thursday-checkbox"];
      delete json.data.attributes["friday-checkbox"];
      delete json.data.attributes["saturday-checkbox"];
      delete json.data.attributes["sunday-checkbox"];
      delete json.data.attributes["day-of-month"];
      delete json.data.attributes["hour"];
      delete json.data.attributes["minutes"];
      delete json.data.attributes["period"];

      return json;
    }
  });
});