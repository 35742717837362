define("map-ui/controllers/forgot/index", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var set = _ember["default"].set;
  var get = _ember["default"].get;
  var service = _ember["default"].inject.service;
  exports["default"] = Controller.extend({
    ajax: service(),
    errorMessage: null,
    wasSent: false,
    identification: null,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        get(this, "ajax").post("password_resets", {
          processData: false,
          contentType: "application/json",
          dataType: "text",
          data: JSON.stringify({
            data: {
              attributes: {
                user: get(this, "identification")
              }
            }
          })
        }).then(function (result) {
          if (result === "created") {
            set(_this, "errorMessage", null);
            set(_this, "wasSent", true);
          }
        })["catch"](function () {
          set(_this, "errorMessage", "Unable to reset password");
        });
      }
    }
  });
});