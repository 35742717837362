define("map-ui/initializers/flash", ["exports", "map-ui/services/flash"], function (exports, _mapUiServicesFlash) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register("service:flash", _mapUiServicesFlash["default"], { singleton: true });
    application.inject("controller", "flashes", "service:flash");
    application.inject("route", "flashes", "service:flash");
    application.inject("component", "flashes", "service:flash");
  }

  exports["default"] = {
    name: "flash",
    initialize: initialize
  };
});