define("map-ui/controllers/patients/show/index", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  exports["default"] = Controller.extend({
    widgets: [{
      id: "test1",
      type: "clock-widget",
      options: {
        test_option: true,
        test_option_2: "hooray"
      },
      x: 5,
      y: 0,
      width: 1,
      height: 1
    }, {
      id: "test1",
      type: "alert-count-widget",
      options: {
        text: "Hey widget"
      },
      x: 7,
      y: 0,
      width: 5,
      height: 6
    }, {
      id: "test1",
      type: "spirometry-linegraph-widget",
      options: {
        text: "Hey other widget"
      },
      x: 0,
      y: 0,
      width: 5,
      height: 6
    }],

    actions: {
      change: function change(event, items) {
        this.set("serializedWidgets", items.map(function (item) {
          return {
            id: items[0].el.attr("data-gs-id"),
            type: items[0].el.attr("data-gs-type"),
            options: items[0].el.attr("data-gs-options"),
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height
          };
        }));
      },
      load: function load() {
        this.set("widgets", this.get("serializedWidgets"));
      }
    }
  });
});