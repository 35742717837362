define("map-ui/models/user", ["exports", "ember-data", "map-ui/mixins/validators/user"], function (exports, _emberData, _mapUiMixinsValidatorsUser) {
  var Model = _emberData["default"].Model;
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsUser["default"], {
    username: attr("string"),
    password: attr("string"),
    passwordConfirmation: attr("string"),
    patientId: attr("string"),
    roles: attr(),

    person: belongsTo("person"),
    client: belongsTo("client")
  });
});