define("map-ui/components/input/checkbox-field", ["exports", "map-ui/components/input/input-field"], function (exports, _mapUiComponentsInputInputField) {
  exports["default"] = _mapUiComponentsInputInputField["default"].extend({
    classNames: ["input", "input__checkbox", "m--xs--b"],
    tagName: "label",
    actions: {
      onchange: function onchange(value) {
        this.set("checked", value);
        this.sendAction("onchange");
      }
    }
  });
});