define("map-ui/models/alert-schedule", ["exports", "ember-data", "map-ui/mixins/validators/alert-schedule"], function (exports, _emberData, _mapUiMixinsValidatorsAlertSchedule) {
  var Model = _emberData["default"].Model;
  var belongsTo = _emberData["default"].belongsTo;
  var attr = _emberData["default"].attr;
  exports["default"] = Model.extend(_mapUiMixinsValidatorsAlertSchedule["default"], {
    scheduleType: attr("string", { defaultValue: "Daily" }),
    cronString: attr("string", { defaultValue: "00 8 * * *" }),
    humanReadableCronString: attr("string"),
    offsetSeconds: attr("number", { defaultValue: "3600" }),
    startsAt: attr("date"),
    endsAt: attr("date"),

    // The following attributes get removed by the serializer and are used to build a cron string.
    // They're appended by the adapter.
    mondayCheckbox: attr("boolean"),
    tuesdayCheckbox: attr("boolean"),
    wednesdayCheckbox: attr("boolean"),
    thursdayCheckbox: attr("boolean"),
    fridayCheckbox: attr("boolean"),
    saturdayCheckbox: attr("boolean"),
    sundayCheckbox: attr("boolean"),
    dayOfMonth: attr("string"),
    hour: attr("string", { defaultValue: "8" }),
    minutes: attr("string", { defaultValue: "00" }),
    period: attr("string", { defaultValue: "AM" }),

    alert: belongsTo("alert", { async: true })
  });
});