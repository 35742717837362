define("map-ui/components/patient/alert/alert-schedule/alert-schedule-row", ["exports", "ember"], function (exports, _ember) {
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  exports["default"] = Component.extend({
    tagName: ["tr"],

    flash: service(),
    eventBus: service(),

    isDeleting: false,
    isShowingAdvanced: false,
    isOpen: computed.oneWay("model.isNew"),

    isOpenStatusChanged: _ember["default"].observer("isOpen", function () {
      if (this.get("isOpen")) {
        this.get("eventBus").publish("alertScheduleRowOpened", get(this, "model"));
      } else {
        this.get("eventBus").publish("alertScheduleRowClosed", get(this, "model"));
      }
    }),

    didInsertElement: function didInsertElement() {
      this.get("eventBus").subscribe("alertScheduleRowOpened", this, "onAlertScheduleRowOpened");
      this.get("eventBus").subscribe("alertRowClosed", this, "onAlertRowClosed");
    },

    willDestroyElement: function willDestroyElement() {
      this.get("eventBus").unsubscribe("alertScheduleRowOpened");
      this.get("eventBus").unsubscribe("alertRowClosed");
    },

    onAlertScheduleRowOpened: function onAlertScheduleRowOpened(alertSchedule) {
      if (alertSchedule !== get(this, "model")) {
        if (this.get("model.hasDirtyAttributes")) {
          get(this, "flash").warning("Alert schedule was not saved. Please press save before closing.");
          this.get("model").rollbackAttributes();
        }
        if (this.get("isOpen")) {
          this.set("isOpen", false);
        }
      }
    },

    onAlertRowClosed: function onAlertRowClosed(alert) {
      if (alert.id === get(this, "model.alert.id")) {
        if (this.get("model.hasDirtyAttributes")) {
          get(this, "flash").warning("Alert schedule was not saved. Please press save before closing.");
          this.get("model").rollbackAttributes();
        }
        if (this.get("isOpen")) {
          this.set("isOpen", false);
        }
      }
    },

    actions: {
      toggle: function toggle() {
        this.toggleProperty("isOpen");
      },
      save: function save() {
        var _this = this;

        if (get(this, "alert.hasDirtyAttributes")) {
          get(this, "alert").validate().then(function () {
            get(_this, "alert").save().then(function () {
              get(_this, "flash").success("Alert saved!");
              _this.send("save");
            })["catch"](function () {
              get(_this, "flash").danger("Unable to save alert.");
            });
          })["catch"](function () {
            set(_this, "errors", get(_this, "alerts").get("errors"));
          });
        } else {
          get(this, "model").validate().then(function () {
            get(_this, "model").save().then(function () {
              get(_this, "flash").success("Alert schedule saved!");
              _this.set("isOpen", false);
            })["catch"](function () {
              get(_this, "flash").danger("Unable to save alert.");
            });
          })["catch"](function () {
            set(_this, "errors", get(_this, "model").get("errors"));
          });
        }
      },
      rollback: function rollback() {
        get(this, "model").rollbackAttributes();
        set(this, "isOpen", false);
      },
      startDelete: function startDelete() {
        set(this, "isDeleting", true);
      },
      cancelDelete: function cancelDelete() {
        set(this, "isDeleting", false);
      },
      confirmDelete: function confirmDelete() {
        get(this, "model").destroyRecord();
      },
      toggleAdvanced: function toggleAdvanced() {
        this.toggleProperty("isShowingAdvanced");
      }
    }
  });
});