define("map-ui/mixins/validators/drug", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    validations: {
      name: {
        presence: true
      },
      nameNormalized: {
        presence: true
      },
      rxString: {
        presence: true
      }
    }
  });
});