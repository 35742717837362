define("map-ui/routes/index", ["exports", "ember", "map-ui/mixins/allowed-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin) {
  exports["default"] = _ember["default"].Route.extend({
    beforeModel: function beforeModel() {
      if (this.get("session.isAuthenticated")) {
        this.transitionTo("patients.index");
      } else {
        this.transitionTo("login");
      }
    }
  });
});