define("map-ui/components/plan/plan-row", ["exports", "ember", "map-ui/config/environment"], function (exports, _ember, _mapUiConfigEnvironment) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "tr",
    isBeingDeleted: false,

    flash: _ember["default"].inject.service(),

    config: _ember["default"].computed("config", function () {
      return _mapUiConfigEnvironment["default"];
    }),

    actions: {
      startDelete: function startDelete() {
        this.set("isBeingDeleted", true);
      },
      confirmDelete: function confirmDelete() {
        var _this = this;

        this.get("patient.carePlans").removeObject(this.get("model"));
        this.get("patient").save()["catch"](function () {
          _this.get("flash").error("Unable to remove care plan!");
        });
      },
      cancelDelete: function cancelDelete() {
        this.set("isBeingDeleted", false);
      }
    }
  });
});