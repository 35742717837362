define("map-ui/components/patient/patient-row", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    tagName: "tr",
    classNameBindings: ["testId"],

    testId: computed(function () {
      return ["qa-patient--" + get(this, "model.id")];
    }),

    isBeingDeleted: false,

    actions: {
      startDelete: function startDelete() {
        set(this, "isBeingDeleted", true);
      },
      confirmDelete: function confirmDelete() {
        get(this, "model").destroyRecord();
      },
      cancelDelete: function cancelDelete() {
        set(this, "isBeingDeleted", false);
      }
    }
  });
});