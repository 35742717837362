define("map-ui/components/input/search-field", ["exports", "ember", "map-ui/components/input/input-field"], function (exports, _ember, _mapUiComponentsInputInputField) {
  var observer = _ember["default"].observer;
  var get = _ember["default"].get;
  var run = _ember["default"].run;
  exports["default"] = _mapUiComponentsInputInputField["default"].extend({
    layoutName: "components/input/input-field",

    placeholder: "Search",

    onUpdate: null,

    _valueDidChange: observer("value", function () {
      run.debounce(this, "_notifyValueUpdate", 300);
    }),

    _notifyValueUpdate: function _notifyValueUpdate() {
      if (get(this, "onUpdate")) {
        this.sendAction("onUpdate");
      }
    }
  });
});