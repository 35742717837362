define("map-ui/components/input/input-field", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    classNames: ["input"],
    attributeBindings: ["testId:test-id"],
    classNameBindings: ["hasValue:input--has-value"],
    type: "text",

    disabled: false,
    required: false,

    hasValue: computed.notEmpty("value"),

    testId: computed("placeholder", function () {
      return get(this, "placeholder").dasherize();
    }),

    actions: {
      keyUp: function keyUp() {
        this.sendAction("keyUp");
      },
      focusOut: function focusOut() {
        this.sendAction("focusOut");
      },
      focusIn: function focusIn() {
        this.sendAction("focusIn");
      },
      enter: function enter() {
        this.sendAction("enter");
      }
    }
  });
});