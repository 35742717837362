define("map-ui/mixins/managed-relationships-mixin", ["exports", "ember", "map-ui/mixins/utils/flatten"], function (exports, _ember, _mapUiMixinsUtilsFlatten) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var A = _ember["default"].A;
  var computed = _ember["default"].computed;
  var Mixin = _ember["default"].Mixin;
  var isEmpty = _ember["default"].isEmpty;
  var on = _ember["default"].on;
  var defineProperty = _ember["default"].defineProperty;

  function isRelationshipManaged(relationship) {
    return relationship.relationshipMeta.options.managed;
  }

  exports["default"] = Mixin.create({
    /* Public API changes */
    isDirty: computed("hasDirtyAttributes", "_hasDirtyRelationships", function () {
      return get(this, "hasDirtyAttributes") || get(this, "_hasDirtyRelationships");
    }),

    rollback: function rollback() {
      if (get(this, "hasDirtyAttributes")) {
        this.rollbackAttributes();
      }
      if (get(this, "_hasDirtyRelationships")) {
        this._rollbackRelationships();
      }
    },

    save: function save() {
      return this._super.apply(this, arguments).then(function (savedModel) {
        savedModel._commitModelAndManagedRelationships();
        return savedModel;
      });
    },

    /* private API */

    _commitModelAndManagedRelationships: function _commitModelAndManagedRelationships() {
      var _this = this;

      var commitThisModel = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (commitThisModel) {
        this._internalModel.adapterWillCommit();
        this._internalModel.updateChangedAttributes();
        this._internalModel.adapterDidCommit();
      }

      this.eachRelationship(function (name, relationship) {
        if (relationship.options.managed) {
          if (relationship.kind === "hasMany") {
            _this._commitManagedHasMany(name);
          } else if (relationship.kind === "belongsTo") {
            _this._commitManagedBelongsTo(name);
          } else {
            throw new Error("this relationship type is not supported " + relationship.kind);
          }
        }
      });
    },

    _commitManagedBelongsTo: function _commitManagedBelongsTo(name) {
      var currentValue = get(this, name).content;
      if (currentValue && currentValue._commitModelAndManagedRelationships) {
        currentValue._commitModelAndManagedRelationships(true);
      }
    },

    _commitManagedHasMany: function _commitManagedHasMany(name) {
      var currentValue = get(this, name).content;
      if (currentValue) {
        currentValue.toArray().forEach(function (model) {
          if (model && model._commitModelAndManagedRelationships) {
            model._commitModelAndManagedRelationships(true);
          }
        });
      }
    },

    _rollbackRelationships: function _rollbackRelationships() {
      var _this2 = this;

      this.eachRelationship(function (name, relationship) {
        if (relationship.options.referenced || relationship.options.managed) {
          if (relationship.kind === "hasMany") {
            _this2._rollbackHasManyRelation(name);
          } else if (relationship.kind === "belongsTo") {
            _this2._rollbackBelongsToRelation(name);
          } else {
            throw new Error("this relationship type is not supported " + relationship.kind);
          }
        }
      });
    },

    _rollbackBelongsToRelation: function _rollbackBelongsToRelation(name) {
      var relationship = this.belongsTo(name).belongsToRelationship;
      if (relationship.hasLoaded && this._isBelongsToDirty(relationship)) {
        var originalValue = null;
        if (relationship.canonicalState) {
          originalValue = this.store.peekRecord(relationship.canonicalState.modelName, relationship.canonicalState.id);
        }

        // let currentValue = get(this, name).content
        set(this, name, originalValue);

        if (originalValue && isRelationshipManaged(relationship) && originalValue.get("isDirty")) {
          originalValue.rollback();
        }
      }
    },

    _rollbackHasManyRelation: function _rollbackHasManyRelation(name) {
      var _this3 = this;

      var relationship = this.hasMany(name).hasManyRelationship;

      var currentValue = A(get(this, relationship.key).content);

      currentValue.clear();

      if (relationship.isPolymorphic) {
        var originalValueIdsAndTypes = A(relationship.canonicalState.map(function (m) {
          return { id: m.id, type: m.modelName };
        }));
        originalValueIdsAndTypes.forEach(function (e) {
          currentValue.addObject(_this3.store.peekRecord(e.type, e.id));
        });
      } else {
        (function () {
          var originalValueIds = A(relationship.canonicalState.map(function (m) {
            return m.id;
          }));

          currentValue.addObjects(_this3.store.peekAll(relationship.belongsToType).filter(function (model) {
            return originalValueIds.contains(model.id);
          }));
        })();
      }

      if (isRelationshipManaged(relationship)) {
        currentValue.forEach(function (model) {
          if (model.get("isDirty")) {
            model.rollback();
          }
        });
      }
    },

    _defineRelationshipComputedProperty: on("init", function () {
      var related = A();

      this.eachRelationship(function (name, relationship) {
        if (relationship.options.referenced || relationship.options.managed) {
          if (!relationship.options.async) {
            throw new Error("Managed relationships mixin works only with async relationships");
          }

          var relationshipData = {
            name: name,
            kind: relationship.kind,
            managed: Boolean(relationship.options.managed)
          };

          if (relationship.kind === "hasMany") {
            relationshipData.keys = [name + ".[]"];

            if (relationshipData.managed) {
              relationshipData.keys.push(name + ".@each.isDirty");
            }
          } else if (relationship.kind === "belongsTo") {
            relationshipData.keys = [name];

            if (relationshipData.managed) {
              relationshipData.keys.push(name + ".isDirty");
            }
          } else {
            throw new Error("this relationship type is not supported " + relationship.kind);
          }
          related.addObject(relationshipData);
        }
      });

      if (!isEmpty(related)) {
        defineProperty(this, "_hasDirtyRelationships", computed.apply(null, [].concat(_toConsumableArray((0, _mapUiMixinsUtilsFlatten["default"])(related.mapBy("keys"))), [function () {
          return this._isAtLeastOneBelongsToDirty(related) || this._isAtLeastOneHasManyDirty(related);
        }])));
      }
    }),

    _isAtLeastOneBelongsToDirty: function _isAtLeastOneBelongsToDirty(relationships) {
      var _this4 = this;

      var belongsTos = relationships.filterBy("kind", "belongsTo");
      var belongsToRelations = belongsTos.map(function (r) {
        return _this4.belongsTo(r.name).belongsToRelationship;
      });

      // We filter out the ones which are not loaded yet, because there cannot be a change from the user side
      var belongsToLoadedRelations = A(belongsToRelations).filterBy("hasLoaded", true);

      return A(belongsToLoadedRelations).any(function (relation) {
        return _this4._isBelongsToDirty(relation);
      });
    },

    _isAtLeastOneHasManyDirty: function _isAtLeastOneHasManyDirty(relationships) {
      var _this5 = this;

      var hasManys = relationships.filterBy("kind", "hasMany");
      var hasManyRelations = hasManys.map(function (r) {
        return _this5.hasMany(r.name).hasManyRelationship;
      });

      // We filter out the ones which are not loaded yet, because there cannot be a change from the user side
      var hasManyLoadedRelations = A(hasManyRelations).filterBy("hasLoaded", true);

      return A(hasManyLoadedRelations).any(function (relation) {
        return _this5._isHasManyDirty(relation);
      });
    },

    _isBelongsToDirty: function _isBelongsToDirty(belongsToRelationship) {
      // Working just on async relations
      var currentValue = get(this, belongsToRelationship.key).content;

      function isReferenceChanged() {
        if (belongsToRelationship.canonicalState) {
          return !currentValue || belongsToRelationship.canonicalState.id !== currentValue.get("id");
        } else {
          return Boolean(currentValue);
        }
      }

      function isTheManegedEntityDirty() {
        if (!currentValue) {
          return false;
        }
        return currentValue.get("isDirty");
      }

      return isRelationshipManaged(belongsToRelationship) ? isReferenceChanged() || isTheManegedEntityDirty() : isReferenceChanged();
    },

    _isHasManyDirty: function _isHasManyDirty(hasManyRelationship) {
      // Working just on async relations
      var currentValue = get(this, hasManyRelationship.key).content;

      function isReferenceChanged() {
        return hasManyRelationship.canonicalState.length !== currentValue.length || A(hasManyRelationship.canonicalState).any(function (e, i) {
          return e === currentValue[i];
        });
      }

      function isTheManegedEntityDirty() {
        return currentValue.any(function (model) {
          return model.get("isDirty");
        });
      }
      return isRelationshipManaged(hasManyRelationship) ? isReferenceChanged() || isTheManegedEntityDirty() : isReferenceChanged();
    }
  });
});