define('map-ui/initializers/ember-cli-bugsnag', ['exports', 'map-ui/config/environment', 'bugsnag', 'ember-cli-bugsnag/utils/bugsnag-configuration'], function (exports, _mapUiConfigEnvironment, _bugsnag, _emberCliBugsnagUtilsBugsnagConfiguration) {
  exports['default'] = {
    name: 'ember-cli-bugsnag',

    initialize: function initialize() {
      var configVariables = _mapUiConfigEnvironment['default'].bugsnag || {};
      var releaseStage = _mapUiConfigEnvironment['default'].bugsnag.releaseStage || _mapUiConfigEnvironment['default'].environment;

      // Set currentRevision value as Bugsnag appVersion
      configVariables.appVersion = _mapUiConfigEnvironment['default'].currentRevision || _mapUiConfigEnvironment['default'].APP.version;

      if (typeof FastBoot === 'undefined') {
        new _emberCliBugsnagUtilsBugsnagConfiguration['default'](configVariables, releaseStage).apply(_bugsnag['default']);
      }
    }
  };
});