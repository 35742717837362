define("map-ui/mixins/validators/user", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    validations: {
      username: {
        presence: true
      },
      currentPassword: {
        absence: true
      },
      "person.firstName": {
        presence: true
      },
      "person.lastName": {
        presence: true
      },
      "person.timeZone": {
        presence: true
      },
      password: {
        confirmation: { message: "passwords must match" },
        presence: {
          "if": "isNew"
        },
        length: {
          minimum: 8,
          "if": function _if(object, validator) {
            if (object.get("password") !== undefined) {
              return true;
            }
            return false;
          }
        },
        format: {
          "with": /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/,
          message: "must contain one capital letter, one lowercase letter, one number, and one special character",
          "if": function _if(object, validator) {
            if (object.get("password") !== undefined) {
              return true;
            }
            return false;
          }
        }
      }
    }
  });
});