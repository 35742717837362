define("map-ui/routes/application", ["exports", "ember", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var _slice = Array.prototype.slice;
  var service = _ember["default"].inject.service;
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  var getOwner = _ember["default"].getOwner;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], {
    sessionUser: service("session-user"),
    session: service(),

    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this,
          _arguments = arguments;

      var _super = this._super;

      return this._loadCurrentUser().then(function () {
        _super.call.apply(_super, [_this].concat(_slice.call(_arguments)));
      });
    },

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },

    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return get(this, "sessionUser").loadCurrentUser()["catch"](function () {
        if (get(_this2, "session.isAuthenticated")) {
          get(_this2, "session").invalidate();
        }
      });
    },

    actions: {
      error: function error(_error) {
        if (_error.errors && _error.errors.length > 0) {
          if (_error.errors[0].status === "401" || _error.errors[0].status === "403") {
            if (get(this, "session.isAuthenticated")) {
              get(this, "session").invalidate();
            }
            this.transitionTo("login");
          }
        } else {
          alert(_error);
        }
      }
    }
  });
});