define("map-ui/controllers/forgot/token", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  exports["default"] = Controller.extend({
    queryParams: ["token"],
    token: null,

    password: null,
    password_confirmation: null,

    ajax: service(),
    errorMessage: false,
    wasUpdated: false,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        get(this, "ajax").post("password_resets/" + get(this, "token"), {
          contentType: "application/json",
          dataType: "text",
          data: JSON.stringify({
            data: {
              attributes: {
                password: get(this, "password"),
                password_confirmation: get(this, "password_confirmation")
              }
            }
          })
        }).then(function () {
          set(_this, "errorMessage", false);
          set(_this, "wasUpdated", true);
        })["catch"](function () {
          set(_this, "errorMessage", true);
        });
      }
    }
  });
});