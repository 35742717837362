define("map-ui/authorizers/map", ["exports", "ember-simple-auth/authorizers/base", "ember"], function (exports, _emberSimpleAuthAuthorizersBase, _ember) {
  var get = _ember["default"].get;
  var service = _ember["default"].inject.service;
  var testing = _ember["default"].testing;
  exports["default"] = _emberSimpleAuthAuthorizersBase["default"].extend({
    session: service(),

    authorize: function authorize(data, block) {
      if (testing) {
        block("Authorization", "Bearer beyond");
      }

      var token = data.token;

      if (get(this, "session.isAuthenticated") && token) {
        block("Authorization", "Bearer " + token);
      }
    }
  });
});