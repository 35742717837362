define("map-ui/utils/cron-deconstructor", ["exports", "ember"], function (exports, _ember) {
  var Object = _ember["default"].Object;
  var set = _ember["default"].set;
  exports["default"] = Object.extend({
    getPropertiesForString: function getPropertiesForString(cronString) {
      var cronStringSplit = cronString.split(" ");

      if (cronStringSplit[2] === "*/2") {
        set(this, "typeOfScheduleField", "every other day");
      } else if (cronStringSplit[2] !== "*") {
        set(this, "typeOfScheduleField", "monthly");
        if (cronStringSplit[2] === "2") {
          set(this, "dayOfMonth", "2nd");
        } else if (cronStringSplit[2] === "3") {
          set(this, "dayOfMonth", "3rd");
        } else {
          set(this, "dayOfMonth", cronStringSplit[2] + "th");
        }
      } else if (cronStringSplit[4] !== "*") {
        set(this, "typeOfScheduleField", "weekly");
        set(this, "mondayCheckbox", cronStringSplit[4].indexOf("1") > -1);
        set(this, "tuesdayCheckbox", cronStringSplit[4].indexOf("2") > -1);
        set(this, "wednesdayCheckbox", cronStringSplit[4].indexOf("3") > -1);
        set(this, "thursdayCheckbox", cronStringSplit[4].indexOf("4") > -1);
        set(this, "fridayCheckbox", cronStringSplit[4].indexOf("5") > -1);
        set(this, "saturdayCheckbox", cronStringSplit[4].indexOf("6") > -1);
        set(this, "sundayCheckbox", cronStringSplit[4].indexOf("7") > -1);
      } else {
        set(this, "typeOfScheduleField", "daily");
      }

      var hour = parseInt(cronStringSplit[1]);

      if (hour > 0 && hour <= 12) {
        set(this, "hour", hour);
      } else if (hour > 12) {
        set(this, "hour", hour - 12);
      } else if (hour === 0) {
        set(this, "hour", 12);
      }

      var minutes = parseInt(cronStringSplit[0]);

      if (minutes < 10 && minutes >= 0) {
        set(this, "minutes", "0" + minutes);
      } else {
        set(this, "minutes", minutes);
      }

      if (hour === "0") {
        set(this, "period", "AM");
      } else {
        if (hour >= 12) {
          set(this, "period", "PM");
        } else {
          set(this, "period", "AM");
        }
      }

      return {
        typeOfScheduleField: this.get("typeOfScheduleField"),
        mondayCheckbox: this.get("mondayCheckbox"),
        tuesdayCheckbox: this.get("tuesdayCheckbox"),
        wednesdayCheckbox: this.get("wednesdayCheckbox"),
        thursdayCheckbox: this.get("thursdayCheckbox"),
        fridayCheckbox: this.get("fridayCheckbox"),
        saturdayCheckbox: this.get("saturdayCheckbox"),
        sundayCheckbox: this.get("sundayCheckbox"),
        dayOfMonth: this.get("dayOfMonth"),
        hour: this.get("hour"),
        minutes: this.get("minutes"),
        period: this.get("period")
      };
    }
  });
});