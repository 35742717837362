define("map-ui/controllers/clients/create", ["exports", "ember"], function (exports, _ember) {
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  var Controller = _ember["default"].Controller;
  exports["default"] = Controller.extend({
    flash: service(),

    actions: {
      save: function save() {
        var _this = this;

        get(this, "model").validate().then(function () {
          get(_this, "model").save().then(function () {
            get(_this, "flash").success("Client saved!");
            _this.transitionToRoute("clients");
          })["catch"](function () {
            get(_this, "flash").danger("Unable to save client.");
          });
        })["catch"](function () {
          set(_this, "errors", get(_this, "model.errors"));
        });
      },
      rollback: function rollback() {
        get(this, "model").rollbackAttributes();
      },
      "delete": function _delete() {
        var _this2 = this;

        get(this, "model").destroyRecord().then(function () {
          get(_this2, "flash").success("Client deleted!");
          _this2.transitionToRoute("clients");
        });
      }
    }
  });
});