define("map-ui/mixins/validators/alert-schedule", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    validations: {
      scheduleType: {
        presence: true
      },
      hour: {
        presence: true
      },
      minutes: {
        presence: true
      },
      period: {
        presence: true
      },
      offsetSeconds: {
        presence: true,
        numericality: true
      },
      dayOfMonth: {
        presence: {
          "if": function _if(object) {
            if (object.get("scheduleType") === "Monthly") {
              return true;
            }
          }
        }
      }
    }
  });
});