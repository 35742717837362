define("map-ui/components/alert/cards/spirometry-alert", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({
    classNames: ["spirometry-alert"],

    alertTypes: [{ id: "text", name: "Text" }, { id: "email", name: "Email" }],

    actions: {
      save: function save() {
        this.sendAction("save");
      }
    }
  });
});