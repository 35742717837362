define("map-ui/helpers/debug", ["exports", "ember"], function (exports, _ember) {
  exports.debug = debug;
  var Helper = _ember["default"].Helper;

  function debug(optionalValue) {
    if (optionalValue && optionalValue.length >= 1) {
      optionalValue.forEach(function (currentValue, index, arr) {
        console.log(currentValue);
      });
    }
  }

  exports["default"] = Helper.helper(debug);
});
/* eslint no-console: "off" */