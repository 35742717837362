define('map-ui/initializers/paste-event', ['exports', 'ember-cli-maskedinput/initializers/paste-event'], function (exports, _emberCliMaskedinputInitializersPasteEvent) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliMaskedinputInitializersPasteEvent['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberCliMaskedinputInitializersPasteEvent.initialize;
    }
  });
});