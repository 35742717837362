define("map-ui/serializers/user", ["exports", "ember-data", "map-ui/mixins/save-relationships"], function (exports, _emberData, _mapUiMixinsSaveRelationships) {
  var JSONAPISerializer = _emberData["default"].JSONAPISerializer;
  exports["default"] = JSONAPISerializer.extend(_mapUiMixinsSaveRelationships["default"], {
    attrs: {
      person: {
        serialize: true
      }
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (key === "password") {
        if (snapshot._attributes.password == null) {
          return false;
        }
      }
      if (key === "passwordConfirmation") {
        if (snapshot._attributes.passwordConfirmation == null) {
          return false;
        }
      }
      this._super(snapshot, json, key, attribute);
    }
  });
});