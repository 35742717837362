define("map-ui/components/drug/drug-selector", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  exports["default"] = Component.extend({
    classNames: ["autofill-selector"],

    store: service(),

    searchQuery: computed.oneWay("model.drug.name"),

    actions: {
      drugNameChanged: function drugNameChanged() {
        var _this = this;

        get(this, "store").query("drug", { query: get(this, "searchQuery") }).then(function (response) {
          set(_this, "drugs", response);
        });
      },
      drugSelected: function drugSelected(drug) {
        set(this, "model.drug", drug);
        set(this, "searchQuery", drug.get("name"));
        set(this, "drugs", null);
      }
    }
  });
});