define("map-ui/components/input/pikaday-field", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var get = _ember["default"].get;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    classNames: ["input"],
    attributeBindings: ["testId:test-id"],
    classNameBindings: ["hasValue:input--has-value"],
    type: "text",

    disabled: false,
    required: false,

    yearRange: "1900,currentYear",

    hasValue: computed.notEmpty("value"),

    testId: computed("placeholder", function () {
      return get(this, "placeholder").dasherize();
    }),

    actions: {
      onSelection: function onSelection(value) {
        this.set("value", value);
      }
    }
  });
});