define("map-ui/mixins/models/alert/alert-type", ["exports", "ember"], function (exports, _ember) {
  var Mixin = _ember["default"].Mixin;
  var get = _ember["default"].get;
  exports["default"] = Mixin.create({
    isGeneralAlert: function isGeneralAlert() {
      if (get(this, "drug.id")) {
        return false;
      }
      return true;
    },

    isMedicationAlert: function isMedicationAlert() {
      if (get(this, "drug.id")) {
        return true;
      }
      return false;
    }
  });
});