define("map-ui/routes/clients/create", ["exports", "ember", "map-ui/mixins/allowed-route-mixin"], function (exports, _ember, _mapUiMixinsAllowedRouteMixin) {
  var Route = _ember["default"].Route;
  var get = _ember["default"].get;
  exports["default"] = Route.extend(_mapUiMixinsAllowedRouteMixin["default"], {
    ability: "show all client",

    model: function model() {
      return get(this, "store").createRecord("client");
    }
  });
});