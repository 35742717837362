define("map-ui/mixins/search-route-mixin", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    queryParams: {
      query: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          page: 1,
          query: null
        });
      }
    }
  });
});