define("map-ui/components/statistics/types/pie-statistic", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    showTable: true,

    chartOptions: {
      animation: {
        animateRotate: true
      },
      cutoutPercentage: 50
    },

    labelData: _ember["default"].computed("data.values", function () {
      return this.get("data.values").map(function (i) {
        return i.name;
      });
    }),

    totalValues: _ember["default"].computed("data.values", function () {
      return this.get("data.values").map(function (i) {
        return i.value;
      }).reduce(function (sum, value) {
        return sum + value;
      });
    }),

    dataSetData: _ember["default"].computed("data.values", function () {
      return this.get("data.values").map(function (i) {
        return i.value;
      });
    }),

    chartData: _ember["default"].computed("dataSetData", "labelData", function () {
      return {
        labels: this.get("labelData"),
        datasets: [{
          data: this.get("dataSetData"),
          hoverBackgroundColor: ["#44d3e9", "#FCFC95", "#FE6860"],
          backgroundColor: ["#0FAECA", "#fafa66", "#fe392f"]
        }]
      };
    })
  });
});