define("map-ui/components/patient/alert/alert-schedule/schedule-builder", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var set = _ember["default"].set;
  exports["default"] = Component.extend({
    schedulerOptions: [{
      id: "daily",
      name: "Daily"
    }, {
      id: "weekly",
      name: "Weekly"
    }, {
      id: "monthly",
      name: "Monthly"
    }],

    monthlyDayOptions: ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"],

    hourOptions: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    minuteOptions: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
    periodOptions: ["AM", "PM"],

    isDaily: computed.equal("model.scheduleType", "daily"),
    isWeekly: computed.equal("model.scheduleType", "weekly"),
    isMonthly: computed.equal("model.scheduleType", "monthly"),

    actions: {
      onScheduleTypeChange: function onScheduleTypeChange(selection) {
        set(this, "model.scheduleType", selection);
      },
      onMonthDayChange: function onMonthDayChange(selection) {
        set(this, "model.dayOfMonth", selection);
      }
    }
  });
});