define("map-ui/authenticators/jwt", ["exports", "ember", "ember-simple-auth/authenticators/base", "map-ui/config/environment"], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _mapUiConfigEnvironment) {
  var Promise = _ember["default"].RSVP.Promise;
  var ajax = _ember["default"].$.ajax;
  var run = _ember["default"].run;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = _emberSimpleAuthAuthenticatorsBase["default"].extend({
    tokenEndpoint: _mapUiConfigEnvironment["default"].host + "/v2/user_token",

    restore: function restore(data) {
      return new Promise(function (resolve, reject) {
        if (!isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate: function authenticate(credentials) {
      var identification = credentials.identification;
      var password = credentials.password;

      var data = JSON.stringify({
        auth: {
          username: identification,
          password: password
        }
      });

      var requestOptions = {
        url: this.tokenEndpoint,
        type: "POST",
        data: data,
        contentType: "application/json",
        dataType: "json"
      };

      return new Promise(function (resolve, reject) {
        ajax(requestOptions).then(function (response) {
          var jwt = response.jwt;

          run(function () {
            resolve({
              token: jwt
            });
          });
        }, function (error) {
          run(function () {
            reject(error);
          });
        });
      });
    },

    invalidate: function invalidate(data) {
      return Promise.resolve(data);
    }
  });
});
/* eslint-disable */