define("map-ui/controllers/login", ["exports", "ember"], function (exports, _ember) {
  var Controller = _ember["default"].Controller;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var service = _ember["default"].inject.service;
  exports["default"] = Controller.extend({
    session: service(),
    sessionUser: service("session-user"),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        get(this, "session").authenticate("authenticator:jwt", this.getProperties("identification", "password"))["catch"](function (reason) {
          set(_this, "password", null);
          set(_this, "errorMessage", reason.error || reason);
        });
      }
    }
  });
});