define("map-ui/models/chat", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    content: _emberData["default"].attr("string"),
    urgent: _emberData["default"].attr("boolean"),
    read: _emberData["default"].attr("boolean"),
    delivered: _emberData["default"].attr("boolean"),
    createdAt: _emberData["default"].attr("date"),

    patient: _emberData["default"].belongsTo("patient"),
    sendingUser: _emberData["default"].belongsTo("user")
  });
});