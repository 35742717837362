define("map-ui/initializers/event-bus", ["exports", "map-ui/services/event-bus"], function (exports, _mapUiServicesEventBus) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register("service:event-bus", _mapUiServicesEventBus["default"], {
      singleton: true
    });
    application.inject("controller", "eventBus", "service:event-bus");
    application.inject("route", "eventBus", "service:event-bus");
    application.inject("component", "eventBus", "service:event-bus");
  }

  exports["default"] = {
    name: "eventBus",
    initialize: initialize
  };
});