define("map-ui/mixins/validators/client", ["exports", "ember", "map-ui/mixins/ember-validations"], function (exports, _ember, _mapUiMixinsEmberValidations) {
  var Mixin = _ember["default"].Mixin;
  exports["default"] = Mixin.create(_mapUiMixinsEmberValidations["default"], {
    validations: {
      name: {
        presence: true
      },
      code: {
        presence: true,
        length: { is: 3 },
        format: {
          "with": /^([a-zA-Z])+$/,
          message: "must be letters only"
        }
      }
    }
  });
});