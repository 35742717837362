define("map-ui/components/client/client-details", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var get = _ember["default"].get;
  exports["default"] = Component.extend({
    deleteField: "",

    canDeleteClient: computed("deleteField", function () {
      if (get(this, "model.name")) {
        return get(this, "deleteField").toUpperCase() === get(this, "model.name").toUpperCase();
      }
      return false;
    }),

    actions: {
      save: function save() {
        this.sendAction("save");
      },
      rollback: function rollback() {
        this.sendAction("rollback");
      },
      "delete": function _delete() {
        this.sendAction("delete");
      }
    }
  });
});