define("map-ui/utils/bugsnag", ["exports", "ember"], function (exports, _ember) {
  exports.getUser = getUser;
  var getProperties = _ember["default"].getProperties;

  function getUser(owner) {
    var currentUser = owner.lookup("service:session-user").get("user");

    var _getProperties = getProperties(currentUser, "username", "id", "person.fullName");

    var username = _getProperties.username;
    var id = _getProperties.id;
    var name = _getProperties.fullName;

    return { username: username, id: id, name: name };
  }
});