define("map-ui/components/user/change-password", ["exports", "ember", "ember-validations"], function (exports, _ember, _emberValidations) {
  var Component = _ember["default"].Component;
  var service = _ember["default"].inject.service;
  var get = _ember["default"].get;
  var set = _ember["default"].set;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_emberValidations["default"], {
    flash: service(),
    ajax: service(),

    passwordsMatch: computed("newPassword", "newPasswordConfirmation", function () {
      return false;
    }),

    showSave: computed.or("currentPassword", "newPassword", "newPasswordConfirmation"),

    canValidate: false,

    validations: {
      newPassword: {
        confirmation: true,
        presence: {
          "if": function _if(object, validator) {
            return get(object, "canValidate");
          }
        }
      },
      currentPassword: {
        presence: {
          "if": function _if(object, validator) {
            get(object, "canValidate");
          }
        }
      }
    },

    actions: {
      save: function save() {
        var _this = this;

        this.validate().then(function () {
          get(_this, "ajax").post("password_change", {
            processData: false,
            contentType: "application/json",
            dataType: "text",
            data: JSON.stringify({
              data: {
                attributes: {
                  current_password: get(_this, "currentPassword"),
                  password: get(_this, "newPassword"),
                  password_confirmation: get(_this, "newPasswordConfirmation")
                }
              }
            })
          }).then(function (result) {
            if (result === "accepted") {
              get(_this, "flash").success("Password was updated");
              set(_this, "currentPassword", null);
              set(_this, "newPassword", null);
              set(_this, "newPasswordConfirmation", null);
            }
          })["catch"](function () {
            get(_this, "flash").error("Unable to change password");
          });
        })["catch"](function (errors) {
          set(_this, "errors", errors);
        });
      }
    }
  });
});